import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faArrowDown, faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = cookieManager.getUserToken();

export class Detailpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      change: [],
      check: 0,
    };
  }

  filterImages(data,removeFile){
    if(removeFile.length >0){
        data.map((file)=>{
          let id=file.id
          if(removeFile.includes(id)){
            return Object.assign(file,{status:2});
          }else{
            return file;
          }
          
        })
    }
    return data
  }
  render() {
    const { choose } = this.props;
    if (choose != null && this.state.check == 0) {
      this.state.detail = choose.log_value;
      this.state.change = choose.change_value;
      this.state.check = 1;
    }
    return (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <Table rules="cols">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>DATA ASLI</td>
                      <td>DATA YANG DIUBAH</td>
                    </tr>
                    <tr
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "5",
                      }}
                    >
                      <td>{i18n.t("No Purchase Order")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.code ? this.state.detail.code : "-"}
                      </td>
                      <td>
                        {this.state.detail.code != this.state.change.code
                          ? this.state.change.code
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Supplier")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.supplier
                          ? this.state.detail.supplier
                          : "-"}
                      </td>
                      <td>
                        {this.state.detail.supplier != this.state.change.supplier
                          ? this.state.change.supplier
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Branch Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.branch
                          ? this.state.detail.branch
                          : "-"}
                      </td>
                      <td>
                        {this.state.detail.branch != this.state.change.branch
                          ? this.state.change.branch
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Store Name")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.store
                          ? this.state.detail.store
                          : "-"}
                      </td>
                      <td>
                        {this.state.detail.store != this.state.change.store
                          ? this.state.change.store
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Date")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.date ? this.state.detail.date : "-"}
                      </td>
                      <td>
                        {this.state.detail.date != this.state.change.date
                          ? this.state.change.date
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Delivery Date")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.deliveryDate
                          ? this.state.detail.deliveryDate
                          : "-"}
                      </td>
                      <td>
                        {this.state.detail.deliveryDate !=
                        this.state.change.deliveryDate
                          ? this.state.change.deliveryDate
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("description")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.description ? this.state.detail.description : "-"}
                      </td>
                      <td>
                        {this.state.detail.description != this.state.change.description
                          ? this.state.change.description
                          : "-"}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                      <td>{i18n.t("Tax")}</td>
                      <td>:</td>
                      <td>
                        {this.state.detail.tax
                          ? this.state.detail.tax
                          : "-"}
                      </td>
                      <td>
                        {this.state.detail.tax != this.state.change.tax
                          ? this.state.change.tax
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row">
            <h3 className="text-center">{i18n.t('Details')}</h3>
            <Table striped bordered size="sm">
              <thead>
              <tr className="bg-success fw-bold">
                  <th>#</th>
                  <th>{i18n.t('Item')}</th>
                  <th>{i18n.t('Unit')}</th>
                  <th>{i18n.t('Qty')}</th>
                  <th>{i18n.t('Price')}</th>
                  <th>{i18n.t('Discount')}</th>
                  <th>{i18n.t('Discount Rate')}</th>
                  <th>{i18n.t('Tax')}</th>
                  <th>{i18n.t('Nett Price')}</th>
                  <th>{i18n.t('Total')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.detail.detail.map((log,i)=>{
                    return (
                      <tr key={'a'+i}>
                        <td>{i+1}</td>
                        <td>{log.item}</td>
                        <td>{log.unit}</td>
                        <td>{log.qty}</td>
                        <td>{log.unitPrice}</td>
                        <td>{log.discount}</td>
                        <td>{log.discountRate}</td>
                        <td>{log.taxPrice}</td>
                        <td>{log.nettPrice}</td>
                        <td>{log.total}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            <p className="text-center"><FontAwesomeIcon icon={faArrowDown} /></p>
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{i18n.t('Item')}</th>
                  <th>{i18n.t('Unit')}</th>
                  <th>{i18n.t('Qty')}</th>
                  <th>{i18n.t('Price')}</th>
                  <th>{i18n.t('Discount')}</th>
                  <th>{i18n.t('Discount Rate')}</th>
                  <th>{i18n.t('Tax')}</th>
                  <th>{i18n.t('Nett Price')}</th>
                  <th>{i18n.t('Total')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.change.detail.map((log,i)=>{
                    return (
                      <tr key={'b'+i}>
                        <td>{i+1}</td>
                        <td>{log.item}</td>
                        <td>{log.unit}</td>
                        <td>{log.qty}</td>
                        <td>{log.unitPrice}</td>
                        <td>{log.discount}</td>
                        <td>{log.discountRate}</td>
                        <td>{log.taxPrice}</td>
                        <td>{log.nettPrice}</td>
                        <td>{log.total}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
            </div>
          </React.Fragment>
        )
  }
}

export default Detailpo;
const loader={
  alignItems: 'center',
  background: '#FFF',
  display: 'flex',
  flexDirection:'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  top: 0,
  transition: 'opacity 0.2s linear',
  width: '100%',
  opacity: 1,
  transform: 'opacity 1s linear',
}