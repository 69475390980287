export default class TrackingPurchasingActions {

    static NEW_DATA = 'NEW_DATA';
    static RESET_DATA = 'RESET_DATA';
    static GET_HELPER_REPORTVISIT = 'GET_HELPER_REPORTVISIT';
    static GET_DATA = 'GET_DATA';
    static SET_TRACKING = 'SET_TRACKING';
    static LOADING_DETAILS = 'LOADING_DETAILS';
    static LOADED_DETAILS = 'LOADED_DETAILS';

    static loadingDetails(){
      return {
        type:TrackingPurchasingActions.LOADING_DETAILS
      }
    }

    static loadedDetails(data){
      return {
        type:TrackingPurchasingActions.LOADED_DETAILS,
        payload:data,
      }
    }

    static new(){
      return {
        type:TrackingPurchasingActions.NEW_DATA
      }
    }
    static process(){
      return {
        type:TrackingPurchasingActions.GET_DATA
      }
    }

    static setTracking(data){
      return {
        type:TrackingPurchasingActions.SET_TRACKING,
        payload:data
      }
    }

    static reset(){
      return {
        type:TrackingPurchasingActions.RESET_DATA
      }
    }
    static helper(data){
      return {
        type:TrackingPurchasingActions.GET_HELPER_REPORTVISIT,
        payload:data
      }
    }
    
  }
  
  