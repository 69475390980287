import { concat } from "lodash";
import i18n from "../../i18n";

//Master Group
const CustomerCategory = [
  "Customer Type List",
  "Customer List",
  "Customer Hierarchy List",
  "Customer Setup Hierarchy List",
 
];
const WarehouseCategory = [
  "Warehouse List",
  "Sub Warehouse",
  "Setup Warehouse",
];
const  SupplierCategory= ["Supplier List"];
const  ItemCategory= [
  "Unit List",
  "Item Type List",
  "Item List",
  "Inventory List",
  "Item Brand List",
  "Item Category List"
];
const UserCategory = [
  "User Approval List", 
  "User List"
];

const ConfigurationAdmin = [
  "Branch List", 
  "Store List",
  "Tax List"
];

const UtilityMenu = concat(CustomerCategory,WarehouseCategory,ItemCategory,SupplierCategory)
const AdminMenu = concat(UserCategory,ConfigurationAdmin)
const MasterGroup=concat(UtilityMenu,AdminMenu)

//Transaction Group
const TransactionCategory=[
    "Offering List",
    "Purchase Request List",
    "Quotation Request List",
    "Purchase Recap Request List",
    "Purchase Order",
]
const ReceiptCategory=[
    "Receiving List",
    "News Event List",
    "Invoice List",
    "Payment List"
]
const ReportCategory=[
    "Invoice Report Show",
]
const WarehouseTransferCategory=[
    "Warehouse Out List",
    "Warehouse In List",
    "Taking Goods List",
]
const MutationCategory=[
    "Mutation List",
    "Stock Card List"
]
const stockMutasi=concat(MutationCategory)
const TransactionMenu=concat(TransactionCategory,ReceiptCategory,ReportCategory)
const WarehouseTransfer=concat(WarehouseTransferCategory)
const TransactionGroup=concat(TransactionMenu,WarehouseTransfer,stockMutasi)

//Accounting Group

const COACategory=[
    "Coa1 List",
    "Coa2 List",
    "Coa3 List",
    "Coa4 List",
    "Coa5 List",
    "Beginning Balance List",
]
const ConfigurationAccCategory=[
    "Bank List",
    "Acc Config List"
]
const AssetTypeCategory=[
    "Asset Type List",
    "Asset List",
]

const FinanceCategory=[
    "Cash Bank Create",
    "Cash Bank Book Show",
    "Purchase Return List",
    "Log BG List",
    "List Payment",
]

const DownPaymentCategory=[
  "Down Payment List",
  "Mutation Down Payment",
]

const AccountingCategory=[
    "Journal List",
    "Memo In List",
    "Memo Out List",
]
const AccountingReportCategory=[
  "Account Payable Show",
  "Uncollected Debt Show",
  "Account Receiveable Show",
  "General Ledger Show",
  "Work Sheet Show",
  "Income Statement Show",
  "Balance Sheet Show",
  "Cash Flow Show",
  "Asset Report Show",
  "Report Tracking Purchasing"
]


const COAMenu=concat(COACategory)
const ConfigurationAccMenu=concat(ConfigurationAccCategory,AssetTypeCategory)
const FinanceMenu=concat(FinanceCategory,DownPaymentCategory)
const AccountingMenu=concat(AccountingCategory)
const AccountingReportMenu=concat(AccountingReportCategory)
const AccountingGroup=concat(AccountingMenu,COAMenu,ConfigurationAccMenu,FinanceMenu,AccountingReportMenu)

//Sales Group
const ScheduleCategory=[
  "Task List",
  "Visit Schedule Show",
  
]
const CustomerTempCategory=[
    "Temperory Customer List",
    "Customer Report Show",
]
const TransactionSalesCategory=[
    "Sales Order List",
    "Delivery Order List",
    "Selling List",
    "Billing List",
    "Settlement Sales List",
    "Payment Confirmation List",
    "Payment Selling List",
]

const MarketingCategory=[
    "Selling Price List",
    "Program Type List",
    "Marketing List",
    
]

const SalesTargetCategory=[
  "Info Distribution List",
  "Sales Target Show"
]

const ReportSalesCategory=[
  "Sales Invoice Report Show",
  "Sales Performance Chart Show",
  "Customer Performance Show"
]

const ScheduleMenu=concat(ScheduleCategory);
const CustomerTempMenu=concat(CustomerTempCategory);
const MarketingMenu=concat(MarketingCategory,SalesTargetCategory);
const TransactionSalesMenu=concat(TransactionSalesCategory);
const ReportSalesMenu=concat(ReportSalesCategory);
const SalesGroup=concat(ScheduleMenu,CustomerTempMenu,MarketingMenu,ReportSalesMenu,TransactionSalesMenu);

//HRD Group
const MasterHrCategory=[
    "Division List",
    "Position List",
    "Category Employee List",
    "Employee List",
]
const SalaryCategory=[
    "Allowance List",
    "Allowance Detail List",
    "Salary Cuts List",
    "Salary Cuts Detail List",
    "Salary Class List",
]
const AbsenceCategory=[
    "Holiday List",
    "Attendance List",
    "Daily Attendance List",
    "Work Calendar List"
]

const MasterHrMenu=concat(MasterHrCategory)
const SalaryHrMenu=concat(SalaryCategory)
const AttendanceHrMenu=concat(AbsenceCategory)
const HumanResourceGroup=concat(MasterHrMenu,SalaryHrMenu,AttendanceHrMenu)

const LogApprovalCategory=[
  "Log Approval List",
  "Log Activity List",
]

const LogApprovalMenu=concat(LogApprovalCategory)
const LogApprovalGroup=concat(LogApprovalMenu)


//Main Menu
const Menu = [
  {
    label: i18n.t("Master"),
    link: "#MasterGroup",
    id: "MasterGroup",
    menuName: MasterGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("Utility"),
        link: "#UtilityMenu",
        id: "UtilityMenu",
        menuName: UtilityMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Customer"),
            link: "#CustomerCategory",
            id: "CustomerCategory",
            menuName: CustomerCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Customer Type"),
                link: "/master/customer-type",
                id: "CustomerType",
                menuName: "Customer Type List",
                visible: true,
              },
              {
                label: i18n.t("Customer List"),
                link: "/master/customer",
                id: "CustomerList",
                menuName: "Customer List",
                visible: true,
              },
              {
                label: i18n.t("Customer Hierarchy"),
                link: "/master/customer-hierarchy",
                id: "CustomerHierarchy",
                menuName: "Customer Hierarchy List",
                visible: true,
              },
              {
                label: i18n.t("Setup Hierarchy"),
                link: "/master/setup-hierarchy",
                id: "SetupHierarchy",
                menuName: "Customer Setup Hierarchy List",
                visible: true,
              },
            ],
          },
          {
            label: i18n.t("Warehouse"),
            link: "#WarehouseCategory",
            id: "WarehouseCategory",
            menuName: WarehouseCategory,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Warehouse List"),
                link: "/master/warehouse",
                id: "WarehouseList",
                menuName: "Warehouse List",
                visible: true,
              },
              {
                label: i18n.t("Sub Warehouse"),
                link: "/master/sub-warehouse",
                id: "SubWarehouse",
                menuName: "Sub Warehouse",
                visible: true,
              },
              {
                label: i18n.t("Setup Warehouse"),
                link: "/master/setup-warehouse",
                id: "SetupWarehouse",
                menuName: "Setup Warehouse",
                visible: true,
              },
            ],
          },
          {
            label: i18n.t("Supplier"),
            link: "#SupplierCategory",
            id: "SupplierCategory",
            menuName: SupplierCategory,
            active: false,
            visible: true,
            childs: [
              {
                label: i18n.t("Supplier List"),
                link: "/master/supplier",
                id: "supplierList",
                menuName: "Supplier List",
                visible: true,
              },
            ],
          },
          {
            label: i18n.t("Item"),
            link: "#ItemCategory",
            id: "ItemCategory",
            menuName: ItemCategory,
            active: false,
            visible: true,
            childs: [
              {
                label: i18n.t("Unit List"),
                link: "/master/unit",
                id: "unitList",
                menuName: "Unit List",
                visible: true,
              },
              
              {
                label: i18n.t("Item Brand List"),
                link: "/master/item-brand",
                id: "itemBrand",
                menuName: "Item Brand List",
                visible: true,
              },
              {
                label: i18n.t("Item Category List"),
                link: "/master/item-category",
                id: "itemCategory",
                menuName: "Item Category List",
                visible: true,
              },
              {
                label: i18n.t("Item Type"),
                link: "/master/item-type",
                id: "itemtypeList",
                menuName: "Item Type List",
                visible: true,
              },
              {
                label: i18n.t("Item List"),
                link: "/master/item",
                id: "itemList",
                menuName: "Item List",
                visible: true,
              },
              {
                label: i18n.t("Inventory List"),
                link: "/master/inventory",
                id: "inventoryList",
                menuName: "Inventory List",
                visible: false,
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Admin"),
        link: "#AdminMenu",
        id: "AdminMenu",
        menuName: AdminMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("User"),
            link: "#UserCategory",
            id: "UserCategory",
            menuName: UserCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("User Assignment"),
                link: "/user/admin",
                id: "UserAssignment",
                menuName: "User List",
                visible: true,
              },
              {
                label: i18n.t("Approval Transaction"),
                link: "/user/approval",
                id: "UserApproval",
                menuName: "User Approval List",
                visible: true,
              }, 
            ],
          },
          {
            label: i18n.t("Configuration"),
            link: "#ConfigurationAdminCategory",
            id: "ConfigurationAdminCategory",
            menuName: ConfigurationAdmin,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Branch List"),
                link: "/master/branch",
                id: "branchList",
                menuName: "Branch List",
                visible: true,
              },
              {
                label: i18n.t("Store List"),
                link: "/master/store",
                id: "storeList",
                menuName: "Store List",
                visible: true,
              },
              {
                label: i18n.t("Tax List"),
                link: "/master/tax",
                id: "TaxList",
                menuName: "Tax List",
                visible: true,
              },
              {
                label: i18n.t("Config Branch"),
                link: "/master/config-branch",
                id: "configbranchList",
                menuName: "Config Branch List",
                visible: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: i18n.t("Purchasing"),
    link: "#TransactionGroup",
    id: "TransactionGroup",
    menuName: TransactionGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("Transaction"),
        link: "#TransactionMenu",
        id: "TransactionMenu",
        menuName: TransactionMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Order"),
            link: "#TransactionCategory",
            id: "TransactionCategory",
            menuName: TransactionCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Goods Offer"),
                link: "/pembelian/offering",
                id: "GoodsOffer",
                visible: true,
                menuName: "Offering List",
              },
              {
                label: i18n.t("Purchase Request"),
                link: "/pembelian/purchase-request",
                id: "PurchaseRequest",
                visible: true,
                menuName: "Purchase Request List",
              },
              {
                label: i18n.t("Quotation Request"),
                link: "/pembelian/quotation",
                id: "QuotationRequest",
                visible: true,
                menuName: "Quotation Request List",
              },
              {
                label: i18n.t("Recap PR"),
                link: "/pembelian/purchase-recap",
                id: "PurchaseRecap",
                visible: true,
                menuName: "Purchase Recap Request List",
              },
              {
                label: i18n.t("Purchase Order"),
                link: "/pembelian/purchase-order",
                id: "PurchaseOrder",
                visible: true,
                menuName: "Purchase Order List",
              },
            ],
          },
          {
            label: i18n.t("Receipt"),
            link: "#ReceiptCategory",
            id: "ReceiptCategory",
            menuName: ReceiptCategory,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Goods Receipt"),
                link: "/pembelian/receiving",
                id: "Goods Receipt",
                visible: true,
                menuName: "Receiving List",
              },
              {
                label: i18n.t("Acceptance Report"),
                link: "/report/acceptance-report",
                id: "AcceptanceReport",
                visible: true,
                menuName: "News Event List",
              },
              {
                label: i18n.t("Purchase Invoice"),
                link: "/pembelian/invoice",
                id: "Invoice",
                visible: true,
                menuName: "Invoice List",
              },
              {
                label: i18n.t("Supplier Payments"),
                link: "/pembelian/payment",
                id: "payment",
                visible: true,
                menuName: "Payment List",
              },
            ],
          },
          {
            label: i18n.t("Report"),
            link: "#ReportCategory",
            id: "ReportCategory",
            menuName: ReportCategory,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Purchase Report"),
                link: "/report/purchasing-report",
                id: "PurchaseReport",
                visible: true,
                menuName: "Invoice Report Show",
              },
            ],
          },
         
        ],
      },
      {
        label: i18n.t("Warehouse Transfer"),
        link: "#WarehouseTransfer",
        id: "WarehouseTransfer",
        menuName: WarehouseTransfer,
        active: false,
        visible: true,
        childs: [
          {
            label: i18n.t("Warehouse Transfer"),
            link: "#WarehouseTransferCategory",
            id: "WarehouseTransferCategory",
            menuName: WarehouseTransferCategory,
            active: true,
            visible: true,
            childs: [
              {
                label: i18n.t("Taking Goods"),
                link: "/warehouse/taking-goods",
                id: "TakingGoods",
                visible: true,
                menuName: "Taking Goods List",
              },
              {
                label: i18n.t("Warehouse Out"),
                link: "/warehouse/warehouse-out",
                id: "WarehouseOut",
                visible: true,
                menuName: "Warehouse Out List",
              },
              {
                label: i18n.t("Warehouse In"),
                link: "/warehouse/warehouse-in",
                id: "WarehouseIn",
                visible: true,
                menuName: "Warehouse In List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Stock Movement"),
        link: "#stockMutasi",
        id: "stockMutasi",
        menuName: stockMutasi,
        active: false,
        visible: true,
        childs: [
          {
            label: i18n.t("Stock Trail Report"),
            link: "#MutationCategory",
            id: "MutationCategory",
            menuName: MutationCategory,
            active: true,
            visible: true,
            childs: [
              {
                label: i18n.t("Stock Trail Report"),
                link: "/mutation",
                id: "mutation",
                visible: true,
                menuName: "Mutation List",
              },
              {
                label: i18n.t("Stock"),
                link: "/stock-card",
                id: "stockcard",
                visible: true,
                menuName: "Stock Card List",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: i18n.t("Sales"),
    link: "#SalesGroup",
    id: "SalesGroup",
    menuName: SalesGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("Schedule"),
        link: "#SchedulesMenu",
        id: "ScheduleMenu",
        menuName: ScheduleMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Schedule"),
            link: "#ScheduleCategory",
            id: "ScheduleCategory",
            menuName: ScheduleCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Sales Activities"),
                link: "/sales/task",
                id: "tasksales",
                visible: true,
                menuName: "Task List",
              },
              {
                label: i18n.t("Visit Schedule"),
                link: "/sales/visit-schedule",
                id: "VisitSchedule",
                visible: true,
                menuName: "Visit Schedule Show",
              },
              {
                label: i18n.t("List Schedule"),
                link: "/sales/list-schedule",
                id: "ListSchedule",
                visible: true,
                menuName: "Visit Schedule Show",
              },
              {
                label: i18n.t("Reconcile Cash Sales"),
                link: "/sales/cash-sales",
                id: "CashSales",
                visible: true,
                menuName: "Cash Sales List",
              },
              
            ],
          },
        ],
      },
      {
        label: i18n.t("Customer"),
        link: "#CustomerTempMenu",
        id: "CustomerTempMenu",
        menuName: CustomerTempMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Customer"),
            link: "#CustomerTempCategory",
            id: "CustomerTempCategory",
            menuName: CustomerTempCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("New Customer"),
                link: "/sales/customer-temp",
                id: "NewCustomer",
                visible: true,
                menuName: "Temperory Customer List",
              },
              {
                label: i18n.t("Customer Report"),
                link: "/report/customer-report",
                id: "CustomerReport",
                menuName: "Customer Report Show",
                visible: true,
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Marketing"),
        link: "#MarketingMenu",
        id: "MarketingMenu",
        menuName: MarketingMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Marketing"),
            link: "#MarketingCategory",
            id: "MarketingCategory",
            menuName: MarketingCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Selling Price"),
                link: "/sales/selling-price",
                id: "SellingPrice",
                visible: true,
                menuName: "Selling Price List",
              },
              {
                label: i18n.t("Return Price"),
                link: "/sales/return-price",
                id: "ReturnPrice",
                visible: true,
                menuName: "Return Price List",
              },
              {
                label: i18n.t("Program Type"),
                link: "/marketing/program-type",
                id: "programType",
                visible: true,
                menuName: "Program Type List",
              },
              {
                label: i18n.t("Marketing Program"),
                link: "/marketing/marketing",
                id: "marketing",
                visible: true,
                menuName: "Marketing List",
              },
            ],
          },
          {
            label: i18n.t("Sales Target"),
            link: "#SalesTargetCategory",
            id: "SalesTargetCategory",
            menuName: SalesTargetCategory,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Info Distribution"),
                link: "/master/info-distribution",
                id: "infoDistribution",
                visible: true,
                menuName: "Info Distribution List",
              },
              {
                label: i18n.t("Sales Target"),
                link: "/sales/sales-target",
                id: "SalesTarget",
                visible: true,
                menuName: "Sales Target Show",
              },
            ],
          },
        ],
       
          
       
      },
      {
        label: i18n.t("Transaction"),
        link: "#TransactionSalesMenu",
        id: "TransactionSalesMenu",
        menuName: TransactionSalesMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Transaction"),
            link: "#TransactionCategory",
            id: "TransactionCategory",
            menuName: TransactionCategory,
            visible: true,
            active: true,
            childs: [
             
              {
                label: i18n.t("Sales Order"),
                link: "/sales/sales-order",
                id: "SalesOrder",
                visible: true,
                menuName: "Sales Order List",
              },
              {
                label: i18n.t("Sales Return"),
                link: "/sales/return-selling",
                id: "ReturnSelling",
                visible: true,
                menuName: "Return Selling List",
              },
              {
                label: i18n.t("Delivery Order"),
                link: "/sales/delivery-order",
                id: "DeliveryOrder",
                visible: true,
                menuName: "Delivery Order List",
              },
              {
                label: i18n.t("Sales Invoice"),
                link: "/sales/sales-invoice",
                id: "SalesInvoice",
                visible: true,
                menuName: "Selling List",
              },
              {
                label: i18n.t("Customer Payments"),
                link: "/sales/payment-invoice",
                id: "PaymentInvoice",
                visible: true,
                menuName: "Payment Confirmation List",
              },
              {
                label: i18n.t("Billing"),
                link: "/sales/billing",
                id: "Billing",
                visible: true,
                menuName: "Billing List",
              },
              {
                label: i18n.t("Sales Settlement"),
                link: "/settlement-sales",
                id: "SalesSettlement",
                visible: true,
                menuName: "Settlement Sales List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Report"),
        link: "#ReportSalesMenu",
        id: "ReportSalesMenu",
        menuName: ReportSalesMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Report"),
            link: "#ReportSalestCategory",
            id: "ReportSalestCategory",
            menuName: ReportSalesCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Sales Report"),
                link: "/report/sales-report",
                id: "SalesReport",
                visible: true,
                menuName: "Sales Invoice Report Show",
              },
              {
                label: i18n.t("Sales Performance"),
                link: "/report/sales-performance",
                id: "SalesPerformance",
                visible: true,
                menuName: "Sales Performance Chart Show",
              },
              {
                label: i18n.t("Customer Performance"),
                link: "/report/customer-performance",
                id: "CustomerPerformance",
                visible: true,
                menuName: "Customer Performance Show",
              },
              {
                label: i18n.t("Visiting Sales"),
                link: "/report/visit-sales",
                id: "VisitSales",
                visible: true,
                menuName: "Report Visit Sales",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Accounting",
    link: "#AccountingGroup",
    id: "AccountingGroup",
    menuName: AccountingGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("COA"),
        link: "#COAMenu",
        id: "COAMenu",
        menuName: COAMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("COA"),
            link: "#COACategory",
            id: "COACategory",
            menuName: COACategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("COA 1"),
                link: "/accounting/coa1",
                id: "Coa1",
                visible: true,
                menuName: "Coa1 List",
              },
              {
                label: i18n.t("COA 2"),
                link: "/accounting/coa2",
                id: "Coa2",
                visible: true,
                menuName: "Coa2 List",
              },
              {
                label: i18n.t("COA 3"),
                link: "/accounting/coa3",
                id: "Coa3",
                visible: true,
                menuName: "Coa3 List",
              },
              {
                label: i18n.t("COA 4"),
                link: "/accounting/coa4",
                id: "Coa4",
                visible: true,
                menuName: "Coa4 List",
              },
              {
                label: i18n.t("COA 5"),
                link: "/accounting/coa5",
                id: "Coa5",
                visible: true,
                menuName: "Coa5 List",
              },
              {
                label: i18n.t("Beginning Balance"),
                link: "/accounting/beginning-balance",
                id: "BeginningBalance",
                visible: true,
                menuName: "Beginning Balance List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Configuration"),
        link: "#ConfigurationAccMenu",
        id: "ConfigurationAccMenu",
        menuName: ConfigurationAccMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Configuration"),
            link: "#ConfigurationAccCategory",
            id: "ConfigurationAccCategory",
            menuName: ConfigurationAccCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Bank"),
                link: "/accounting/bank",
                id: "Bank",
                visible: true,
                menuName: "Bank List",
              },
              {
                label: i18n.t("Accounting Setup"),
                link: "/accounting/configuration",
                id: "Configuration",
                visible: true,
                menuName: "Acc Config List",
              },
            ],
          },
          {
            label: i18n.t("Asset"),
            link: "#AssetTypeCategory",
            id: "AssetTypeCategory",
            menuName: AssetTypeCategory,
            visible: true,
            active: false,
            childs: [
              {
                label: i18n.t("Asset Type"),
                link: "/accounting/asset-type",
                id: "AssetType",
                visible: true,
                menuName: "Asset Type List",
              },
              {
                label: i18n.t("Asset List"),
                link: "/accounting/asset",
                id: "Asset",
                visible: true,
                menuName: "Asset List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Finance"),
        link: "#FinanceMenu",
        id: "FinanceMenu",
        menuName: FinanceMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Finance"),
            link: "#FinanceCategory",
            id: "FinanceCategory",
            menuName: FinanceCategory,
            active: true,
            visible: true,
            childs: [
              {
                label: i18n.t("Cash / Bank"),
                link: "/accounting/cash-bank",
                id: "CashBank",
                visible: true,
                menuName: "Cash Bank Create",
              },
              {
                label: i18n.t("Cash Bank Book"),
                link: "/report/cash-bank",
                id: "cashbank book",
                visible: true,
                menuName: "Cash Bank Book Show",
              },
              {
                label: i18n.t("Purchase Return"),
                link: "/pembelian/purchase-return",
                id: "PurchaseReturn",
                visible: true,
                menuName: "Purchase Return List",
              },
              {
                label: i18n.t("Billyet Giro"),
                link: "/accounting/log-bg",
                id: "LogBg",
                visible: true,
                menuName: "Log BG List",
              },
              {
                label: i18n.t("List Payment"),
                link: "/accounting/list-payment",
                id: "ListPayment",
                visible: true,
                menuName: "List Payment",
              },
            ],
          },
          {
            label: i18n.t("Down Payment"),
            link: "#DownPaymentCategory",
            id: "DownPaymentCategory",
            menuName: DownPaymentCategory,
            active: false,
            visible: true,
            childs: [
              {
                label: i18n.t("Down Payment"),
                link: "/accounting/down-payment",
                id: "DownPayment",
                visible: true,
                menuName: "Down Payment List",
              },
              {
                label: i18n.t("Mutation Down Payment"),
                link: "/mutation-deposit",
                id: "MutationDeposit",
                visible: true,
                menuName: "Mutation Deposit List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Accounting"),
        link: "#AccountingMenu",
        id: "AccountingMenu",
        menuName: AccountingMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Accounting"),
            link: "#AccountingCategory",
            id: "AccountingCategory",
            menuName: AccountingCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("General Ledger"),
                link: "/accounting/journal",
                id: "GeneralLedger",
                visible: true,
                menuName: "Journal List",
              },
              {
                label: i18n.t("Memo In"),
                link: "/accounting/memo-in",
                id: "MemoIn",
                visible: true,
                menuName: "Memo In List",
              },
              {
                label: i18n.t("Memo Out"),
                link: "/accounting/memo-out",
                id: "MemoOut",
                visible: true,
                menuName: "Memo Out List",
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Report"),
        link: "#AccountingReportMenu",
        id: "AccountingReportMenu",
        menuName: AccountingReportMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Report"),
            link: "#AccountingReportCategory",
            id: "AccountingReportCategory",
            menuName: AccountingReportCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Uncollected Receivables"),
                link: "/report/uncollected-debt",
                id: "uncollected debt",
                visible: true,
                menuName: "Uncollected Debt Show",
              },
              {
                label: i18n.t("Account Payable"),
                link: "/report/account-payable",
                id: "account payable",
                visible: true,
                menuName: "Account Payable Show",
              },
              {
                label: i18n.t("Account Receiveable"),
                link: "/report/account-receiveable",
                id: "account receiveable",
                visible: true,
                menuName: "Account Receiveable Show",
              },
              {
                label: i18n.t("Ledger"),
                link: "/report/general-ledger",
                id: "general ledger",
                visible: true,
                menuName: "General Ledger Show",
              },
              {
                label: i18n.t("Work Sheet"),
                link: "/report/work-sheet",
                id: "work sheet",
                visible: true,
                menuName: "Work Sheet Show",
              },
              {
                label: i18n.t("Income Statement"),
                link: "/report/income-statement",
                id: "income statement",
                visible: true,
                menuName: "Income Statement Show",
              },
              {
                label: i18n.t("Balance Sheet"),
                link: "/report/balance-sheet",
                id: "balance sheet",
                visible: true,
                menuName: "Balance Sheet Show",
              },
              {
                label: i18n.t("Cash Flow"),
                link: "/report/cash-flow",
                id: "cast flow",
                visible: true,
                menuName: "Cash Flow Show",
              },
              {
                label: i18n.t("Asset Report"),
                link: "/report/asset-report",
                id: "asset report",
                visible: true,
                menuName: "Asset Report Show",
              },
              {
                label: i18n.t("Tracking Purchasing"),
                link: "/report/tracking-purchasing",
                id: "tracking purchasing",
                visible: true,
                menuName: "Report Tracking Purchasing",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: i18n.t("Human Resource"),
    link: "#HumanResourceGroup",
    id: "HumanResourceGroup",
    menuName: HumanResourceGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("Master"),
        link: "#MasterHrMenu",
        id: "MasterHrMenu",
        menuName: MasterHrMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Master"),
            link: "#MasterHrCategory",
            id: "MasterHrCategory",
            menuName: MasterHrCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Division"),
                link: "/hr/division",
                id: "divison",
                menuName: "Division List",
                visible: true,
              },
              {
                label: i18n.t("Position"),
                link: "/hr/position",
                id: "position",
                menuName: "Position List",
                visible: true,
              },
              {
                label: i18n.t("Category"),
                link: "/hr/category-employee",
                id: "categoryEmployee",
                menuName: "Category Employee List",
                visible: true,
              },
              {
                label: i18n.t("Employee List"),
                link: "/hr/employee",
                id: "employee",
                menuName: "Employee List",
                visible: true,
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Salary"),
        link: "#SalaryHrMenu",
        id: "SalaryHrMenu",
        menuName: SalaryHrMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Salary"),
            link: "#SalaryCategory",
            id: "SalaryCategory",
            menuName: SalaryCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Allowance"),
                link: "/hr/allowance",
                id: "allowance",
                menuName: "Allowance List",
                visible: true,
              },
              {
                label: i18n.t("Allowance Detail"),
                link: "/hr/allowance-detail",
                id: "allowanceDetail",
                menuName: "Allowance Detail List",
                visible: true,
              },
              {
                label: i18n.t("Salary Cuts"),
                link: "/hr/salary-cuts",
                id: "salaryCuts",
                menuName: "Salary Cuts List",
                visible: true,
              },
              {
                label: i18n.t("Salary Cuts Detail"),
                link: "/hr/salary-cuts-detail",
                id: "salaryCutsDetail",
                menuName: "Salary Cuts Detail List",
                visible: true,
              },
              {
                label: i18n.t("Salary Class"),
                link: "/hr/salary-class",
                id: "salaryClass",
                menuName: "Salary Class List",
                visible: true,
              },
            ],
          },
        ],
      },
      {
        label: i18n.t("Attendance"),
        link: "#AttendanceHrMenu",
        id: "AttendanceHrMenu",
        menuName: AttendanceHrMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Attendance"),
            link: "#AbsenceCategory",
            id: "AbsenceCategory",
            menuName: AbsenceCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Holiday"),
                link: "/hr/holiday",
                id: "holiday",
                menuName: "Holiday List",
                visible: true,
              },
              {
                label: i18n.t("Work Calendar"),
                link: "/master/work-calendar",
                id: "work-calendar",
                menuName: "Work Calendar List",
                visible: true,
              },
              {
                label: i18n.t("Attendance Type"),
                link: "/hr/attendance",
                id: "attendance",
                menuName: "Attendance List",
                visible: true,
              },
              {
                label: i18n.t("Daily Attendance"),
                link: "/hr/daily-attendance",
                id: "dailyAttendance",
                menuName: "Daily Attendance List",
                visible: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: i18n.t("Authorization"),
    link: "#LogApprovalGroup",
    id: "LogApprovalGroup",
    menuName: LogApprovalGroup,
    visible: true,
    childs: [
      {
        label: i18n.t("Log Approval"),
        link: "#LogApprovalMenu",
        id: "LogApprovalMenu",
        menuName: LogApprovalMenu,
        visible: true,
        childs: [
          {
            label: i18n.t("Log Approval"),
            link: "#LogApprovalCategory",
            id: "LogApprovalCategory",
            menuName: LogApprovalCategory,
            visible: true,
            active: true,
            childs: [
              {
                label: i18n.t("Approval"),
                link: "/log/approval",
                id: "logApproval",
                visible: true,
                menuName: "Log Approval List",
              },
              {
                label: i18n.t("Activity"),
                link: "/log/activity",
                id: "logActivity",
                visible: false,
                menuName: "Log Activity List",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Menu;
