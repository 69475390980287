import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { TrackingPurchasing } from "../../../../components/modules/report/tracking-purchasing";
import TrackingPurchasingMiddleware from "../../../../middleware/modules/report/tracking-purchasing";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ trackingPurchasing }) => ({
  isProcessing: trackingPurchasing.isProcessing,
  isError: trackingPurchasing.isError,
  errorMessage: trackingPurchasing.errorMessage,
  datalist: trackingPurchasing.data,
  helper:trackingPurchasing.helperTrackingPurchasing,
  tracking:trackingPurchasing.timeline,
  dataDetails:trackingPurchasing.details,
  loading:trackingPurchasing.loadingDetails,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper:()=>TrackingPurchasingMiddleware.getHelper(),
      reset: () =>TrackingPurchasingMiddleware.reset(),
      getTracking:(post)=>TrackingPurchasingMiddleware.Tracking(post),
      getData:(type,id)=>TrackingPurchasingMiddleware.getData(type,id)
    },
    dispatch
  );
};
class TrackingPurchasingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.reset();
    this.props.getHelper();
  }
  
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Report Visit")}
          </title>
        </Helmet>
      <TrackingPurchasing
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingPurchasingContainer);
