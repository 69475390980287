import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import i18n from '../../../../i18n';
import cookieManager from '../../../../utils/cookieManager';
import Select2 from '../../../utilities/Form/Select2';
const token= cookieManager.getUserToken();

export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
            },
            branchList:[],
            branchValue:null,
            branchText:i18n.t("Select Branch"),
            warehouseText:i18n.t("Select Warehouse"),
            storeList:[],
            storeValue:null,
            storeText:i18n.t("Select Store"),
            subWarehouseText:i18n.t("Select Sub Warehouse"),
            status:1,
            warehouseList:[],
            arrayDetail:[
                {
                    
                    subWarehouseList:[],
                    subWarehouseValue:null,
                    warehouseValue:null,
                }
            ],
            isUpdate:0
        }
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerSelectArray = this.handlerSelectArray.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
    }
   
   
    handlerSelect(e,currentState) {
        this.setState({[currentState]:e})
    }
    runSubmit(){
        const {isNewRecord,dataUpdate,search}=this.props
        const detail=this.state.arrayDetail
        let warehouse=[]
        let subwarehouse=[]
        let branch=[]
        let store=[]

        for(var i=0;i<detail.length;i++){
            warehouse.push((detail[i].warehouseValue !==null)?detail[i].warehouseValue.value:'');
            subwarehouse.push((detail[i].subWarehouseValue !==null)?detail[i].subWarehouseValue.value:'');
            branch.push((this.state.branchValue !==null)?this.state.branchValue.value:'');
            store.push((this.state.storeValue !==null)?this.state.storeValue.value:'');
        }
        if(warehouse.length ==0){
            Swal.fire('Warning',i18n.t('Warehouse has not been selected'),'warning')
        }else{
            this.state.form.SetupWarehouse={
                idBranch:branch,
                idStore:store,
                idWarehouse:warehouse,
                idSubwarehouse:subwarehouse
            }
            if(isNewRecord){
                this.props.new(this.state.form,search)
              } else{
                this.props.update(dataUpdate.id,this.state.form,search)
              }
        }
        
        
    }
    handlerSelectArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            objectss[name] = null;
        }else{
            objectss[name] = e;
        }
        this.setState(arr)
    }
 
    
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    renderDetail(detail,i){
        const {data,unitList}=this.props    
        return <tr key={i}>
                    <td >
                        <Select
                            isClearable={true}
                            name={'warehouse'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                await this.handlerSelectArray(e,"warehouseValue",i,"arrayDetail")
                                if(e!=null){
                                    await this.changeData('subWarehouseList','getSubWarehouse','','arrayDetail',i)
                                }else{
                                    await this.handlerSelectArray(e,"subWarehouseValue",i,"arrayDetail")
                                }
                                
                            }}
                            options={this.state.warehouseList}
                            value={detail.warehouseValue}
                            placeholder={this.state.warehouseText}
                            
                        />
                        <div className="fv-plugins-message-container invalid-feedback">{this.renderError('SetupWarehouse.idWarehouse.'+i)}</div>
                    </td>
                    <td >
                        <Select
                            isClearable={true}
                            name={'subWarehouse'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                await this.handlerSelectArray(e,"subWarehouseValue",i,"arrayDetail")
                            }}
                            options={detail.subWarehouseList}
                            value={detail.subWarehouseValue}
                            placeholder={this.state.subWarehouseText}
                            
                        />
                         <div className="fv-plugins-message-container invalid-feedback">{this.renderError('SetupWarehouse.idSubWarehouse.'+i)}</div>
                    </td>
                    <td>
                        <Button variant='danger' className='btn btn-sm' onClick={(e)=>this.removeArray(i,'arrayDetail')}>
                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </Button>
                    </td>
            </tr>
    }
    addArray(value,key){
        const newRow=value
        const arr=[...this.state[key],newRow]
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    removeArray(i,key){
        const arr = this.state[key].filter( (obj,index) => {
            return index !== i;
          })
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    changeData(keyFill,type=null,key='',array=null,arraykey=null){
        let params=null
        switch(type){
            case 'getStore':
                var url=process.env.REST_URL +'helper/get-store'
                var branch=((this.state.branchValue !=undefined)?this.state.branchValue.value:null)
                params={
                    id:branch,
                }
            break;
            case 'getSubWarehouse':
                var url=process.env.REST_URL +'helper/get-sub-warehouse'
                var idWarehouse=((this.state[array][arraykey].warehouseValue !=undefined)?this.state[array][arraykey].warehouseValue.value:null)
                params={
                    isParent:idWarehouse,
                }
            break
        }

        if(type !=null){
            axios({
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
                },
                url:url,
                responseType: 'json',
                params:params
              })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if(array!=null){
                        var arr = [...this.state[array]];
                        const objectss = arr[arraykey]
                        if(key==''){
                            objectss[keyFill] = response.data.result;
                        }else{
                            objectss[keyFill] = response.data.result[key];
                        }
                    }else{
                        if(key==''){
                           var arr= {[keyFill]:response.data.result}
                        }else{
                            var arr= {[keyFill]:response.data.result[key]}
                        }
                    }
                    this.setState(arr)
                }
              })
              .catch((error) => {
                console.log(error)
              });
        }

    }
    render(){
        const {dataUpdate,isNewRecord,helper}=this.props
        this.state.branchList=(helper!=null)?helper.branch:[]
        this.state.warehouseList=(helper!=null)?helper.warehouse:[]

        if(dataUpdate !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.branchValue=(dataUpdate.branch_id!=null)?{value:dataUpdate.branch_id,label:dataUpdate.branch_name}:null
                this.state.storeValue=(dataUpdate.store_id!=null)?{value:dataUpdate.store_id,label:dataUpdate.store_name}:null
                this.state.arrayDetail=[]
                this.changeData('storeList','getStore','')
                for(var po=0;po<dataUpdate.details.length;po++){
                    this.state.arrayDetail.push(
                        {
                            subWarehouseList:[],
                            subWarehouseValue:(dataUpdate.details[po].sub_warehouse_id!=null)?{value:dataUpdate.details[po].sub_warehouse_id,label:dataUpdate.details[po].sub_warehouse_name}:null,
                            warehouseValue:(dataUpdate.details[po].warehouse_id!=null)?{value:dataUpdate.details[po].warehouse_id,label:dataUpdate.details[po].warehouse_name}:null,
                        }
                    )
                    this.changeData('subWarehouseList','getSubWarehouse','','arrayDetail',po)
                }
                this.state.isUpdate=1
            }
        }
        return (
            <form>
                <Select2 
                    isClearable={true}
                    name={'branchValue'}
                    title={i18n.t("Branch")}
                    required={true}
                    className={""}
                    size={4}
                    onChange={async (e)=>{
                        await this.handlerSelect(e,"branchValue")
                        await this.changeData('storeList','getStore','')
                    }}
                    options={this.state.branchList}
                    value={this.state.branchValue}
                    placeholder={this.state.branchText}
                    error={this.renderError('SetupWarehouse.idBranch.0')}
                />
                <Select2 
                    isClearable={true}
                    name={'storeValue'}
                    title={i18n.t("Store")}
                    required={false}
                    className={""}
                    size={4}
                    onChange={async (e)=>{
                        await this.handlerSelect(e,"storeValue")
                    }}
                    options={this.state.storeList}
                    value={this.state.storeValue}
                    placeholder={this.state.storeText}
                
                />
                
                <div className='row'>
                    <div className="col-12">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>{i18n.t("Warehouse")}</th>
                                    <th className='text-center'>{i18n.t("SubWarehouse")}</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                       
                        <Button variant='success' onClick={
                        (e)=>this.addArray(
                            {
                                subWarehouseList:[],
                                subWarehouseValue:null,
                                warehouseValue:null,
                            },
                            'arrayDetail'
                        )}>{i18n.t("Add Warehouse")}</Button>
                    </div>
                </div>
                
            </form>
        );
    }
}

export default Form;