import React, { Component, Fragment, useState } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Pagination, Spinner, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf,faEye, faFileExcel, faDisplay } from "@fortawesome/free-solid-svg-icons";
import cookieManager from "../../../../utils/cookieManager";
import { Link } from "react-router-dom";
import Radio from "../../../utilities/Form/radio";
import { Period } from "../../../utilities/Const/StatusActive";
import Input from "../../../utilities/Form/input";
import moment from "moment/moment";
const MySwal = withReactContent(Swal);
export class WorkSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsPerPage : 21,
      currentPage : 1,
      setCurrentPage : 1,
      maxPaginationItems: 5,
      branchCurrent: null,
      branchList: [],
      branchText: i18n.t('All'),
      periodCurrent: null,
      periodText: i18n.t("Select Week"),
      periodList: [],
      coa3Current: null,
      coa3Text: i18n.t("Select Coa3"),
      coa3List: [],
      idCoa3: null,
      listData: [],
      onShow:false,
      startDate: new Date(),
      endDate: new Date(),
      startDateExcel: new Date(),
      endDateExcel: new Date(),
      page: 1,
      fill:0,
      head:0,
      bound:0,
      type: 2,
      show: 0,
      search:'',
      beginningDebt:0,
      beginningCredit:0,
      debt:0,
      credit:0,
      fill:0,
      endingCredit:0,
      endingDebt:0,
      limitCurrent:{value: '25', label: '25' },
      pageLimit: [
        { value: '1', label: 'All' },
        { value: '10', label: '10' },
        { value: '25', label: '25' },
        { value: '50', label: '50' },
      ],
      titleModal: "",
      headeTable: false,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("General Ledger"), class: " text-gray-500" },
      ]
    };
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.setData = this.setData.bind(this);
    this.formData = React.createRef();
    this.handlerRadio = this.handlerRadio.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
  }

  handlerRadio(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }

  _onBlur() {
    this.setState({
      page: 1,
      limit: 1,
    });
    this.setData();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.setState({
        page: 1,
        limit: 1,
      });
      this.setData();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e
    this.setState({
      [key]: value
    }
    )
  }

  handlerSelect(e, name, currentState) {
    var key = name
    var value = null
    if (e != null) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e
    }
    )
  }
  
  handlerSearch(e) {
    console.log('masuj');

    // var key = e.target.name;
    // var value = e.target.value;
    // this.setState({
    //   [key]: value,
    // });
      var value = e.target.value;
      console.log(value)
      let data = this.state.listData
      var updateList = [...this.state.list];
      if(value != ""){
        
        updateList = updateList.filter((item)=>{
          if(item.acc_name != null){
            return item.acc_id.toLowerCase().includes(value.toLowerCase()) ||
                    item.acc_name.toLowerCase().includes(value.toLowerCase()) ;
                    // item.beginning_balance.toLowerCase().includes(value.toLowerCase()) ||
                    // item.beginning_diff.toLowerCase().includes(value.toLowerCase()) ||
                    // item.credit.toLowerCase().includes(value.toLowerCase())||
                    // item.debt.toLowerCase().includes(value.toLowerCase())||
                    // item.ending_diff.toLowerCase().includes(value.toLowerCase())||
                    // item.ending.toLowerCase().includes(value.toLowerCase());
          }
      })

      console.log(updateList)
      this.setState({
        bound:1,
        search:value,
        listData: updateList,
      });
      }else{
        this.setState({
          fill: 1,
          search:'',
          bound:0,
        });
      }
    }
  

  setData() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null ? '' : this.state.periodCurrent.endDate);
    var limit = this.state.limitCurrent.value;
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
    var page = (this.state.page == null) ? 1 : this.state.page;
    var type = this.state.type;
    // var search = (this.state.search == null) ? '' : this.state.search;


    let params = {
      startDate: startDate,
      endDate: endDate,
      limit: limit,
      page: page,
      type: type,
      // search:search,
      idBranch:idBranch
    }
    this.props.setData(params);
  }

  renderList(currentItems) {
    let html = [];
    let no = 0;
    if (currentItems != undefined && currentItems != null) {
      let row =  currentItems
        // Swal.close();
        for (var p = 1; p <= row.length; p++) {
          no++;
          if (row[p] != null) {
            html.push(
              <tr key={p} className="border">
                <td className="text-center">{no}</td>
                <td>{row[p].acc_id}</td>
                <td>{row[p].acc_name}</td>
                <td style={{ textAlign: 'right' }}>
                  {(row[p].position == 'Debt' && row[p].beginning_balance >= 0)?row[p].beginning_balance_format:
                  (row[p].position == 'Credit' && row[p].beginning_balance < 0)?row[p].beginning_diff_format:'0.00'}</td>
                <td style={{ textAlign: 'right' }}> 
                  {(row[p].position == 'Credit' && row[p].beginning_balance >= 0)?row[p].beginning_balance_format:
                  (row[p].position == 'Debt' && row[p].beginning_balance < 0)?row[p].beginning_diff_format:'0.00'}</td>
                <td style={{ textAlign: 'right' }}> {row[p].debt_format}</td>
                <td style={{ textAlign: 'right' }}> {row[p].credit_format}</td>
                <td style={{ textAlign: 'right' }}> 
                  {(row[p].position == 'Debt' && row[p].ending >= 0 )?row[p].ending_format:
                  (row[p].position == 'Credit' && row[p].ending < 0)?row[p].ending_diff_format:'0.00'}</td>
                <td style={{ textAlign: 'right' ,paddingRight:'10px'}}>
                  {(row[p].position == 'Credit' && row[p].ending >= 0)?row[p].ending_format:
                  (row[p].position == 'Debt' && row[p].ending < 0)?row[p].ending_diff_format:'0.00'} </td>
              </tr>)
          }
         
        
      
        // html.push(
        //   <tr className="border" >
        //     <td colSpan={'3'} style={{ textAlign: "center", fontWeight: 'bold' }}>TOTAL</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(beginningdebt)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(beginningcredit)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(debt)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(credit)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(endingdebt)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        //     <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{parseFloat(endingcredit)
        //       .toFixed(2)
        //       .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>  
        //   </tr>)

      }
    }
    return html;

  }

  renderGrandTotal(){
    
    let render = [];
    let beginningdebt = 0;
    let beginningcredit = 0;
    let debt = 0;
    let credit = 0;
    let endingdebt = 0;
    let endingcredit = 0;
    if (this.state.list != undefined && this.state.list != null) {
      let row =  this.state.list
        for (var p = 1; p <= row.length; p++) {
          if (row[p] != null) {
            debt += parseFloat(row[p].debt) || 0;
            credit += parseFloat(row[p].credit) || 0;
            beginningcredit += (row[p].position == 'Credit')?parseFloat(row[p].beginning_balance) || 0 : 0;
            beginningdebt += (row[p].position == 'Debt')?parseFloat(row[p].beginning_balance) || 0 : 0;
            endingdebt += (row[p].position == 'Debt')?parseFloat(row[p].ending) || 0 : 0;
            endingcredit += (row[p].position == 'Credit')?parseFloat(row[p].ending) || 0 : 0;
          }
        }
    // if (data.data != undefined) {
    //   let row = data.data;
    //   if (data != undefined && data != null) {
          
        
    //   }
    // }
    render.push(
      <tr key={"total"} style={{fontWeight:'bold', fontSize:20}}>
        <td >{parseFloat(beginningdebt)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td >{parseFloat(beginningcredit)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td >{parseFloat(debt)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td >{parseFloat(credit)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td >{parseFloat(endingdebt)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
        <td >{parseFloat(endingcredit)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</td>
      </tr>
    );
      }
    return render;
  }

  alertHandler(title, message, icon, i) {
    Swal.fire({
      title: title,
      html: message,
      icon: icon,
    });
  }

  renderLoading() {
    Swal.fire({
      // loaderHtml: '<div class="spinner-border text-primary"></div>',
      title: 'Loading...',
      width: "200px",
      heightAuto: true,
      allowOutsideClick:false,
     
    });
    Swal.showLoading();
  }

  async convertExcel() {
    var startDate = this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate);
    var endDate = this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate);
    var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;

    this.renderLoading();
    const token = cookieManager.getUserToken();
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REST_URL + "report/work-sheet/excel",
      method: "GET",
      params: {
        startDate: startDate,
        endDate: endDate,
        idBranch :idBranch
      },
      responseType: "blob",
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        this.alertHandler('Convert Excel', 'Download Successfully', 'success')
        var urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(response.data);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = blobUrl;
        link.setAttribute("download",
        moment(startDate).format("D/M/YYYY") + ' - ' +
        moment(endDate).format("D/M/YYYY") + ' ' +
        ' Laporan Neraca Lajur.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        // this.setState({ onDownload: !this.state.onDownload });
      } else {
        // this.setState({ onDownload: !this.state.onDownload });
      }
    }).catch((error) => {
      this.alertHandler('Convert Excel', 'Download Failed', this.renderError('branch'))
    });
  }

  changeLimit(e, name, currentState) {
    var key = name
    var value = null
    if (e != 1) {
      value = e.value
    }
    this.setState({
      [key]: value,
      [currentState]: e,
      page: 1
    }, () => this.setData()
    )

  }

  changePage(event, url, count) {
    let get = parseInt(this.state.page) || 0;
    if (event.target.value == 0) {
      this.setState({ page: (get - 1) }, () => this.setData());
    } else if (event.target.value == (count - 1)) {
      this.setState({ page: (get + 1) }, () => this.setData());
    } else {
      this.setState({ page: event.target.value }, () => this.setData())
    }
  }

  renderPagination() {
    const { isProcessing, helperList, datalist, data } = this.props;

    this.state.limit = 10;
    return (
      <React.Fragment>
        <div className="row">
          <div className="dataTables_length col-sm-9">
            <label>
              <Select2
                name={"limit"}
                labelfield={"false"}
                onChange={async (e) => {
                  this.changeLimit(e, "limit", "limitCurrent")
                }}
                options={this.state.pageLimit}
                title={i18n.t("")}
                value={this.state.limitCurrent}
              />
            </label>
          </div>
          <div
            className="col-sm-3"
            id="kt_customers_table_paginate"
          >
            <ul className="pagination">
              {data.links != null
                ? data.links.map((item, i) => (
                  <li
                    className={
                      "paginate_button page-item" +
                      (i == 0 ? " previous" : "") +
                      (i == data.links.length - 1 ? " next" : "") +
                      (item.url == null ? " disabled" : "") +
                      (item.active ? " active" : "")
                    }
                    key={i}
                  >
                    <button
                      key={i}
                      onClick={(e) => this.changePage(e, item.url, data.links.length)}
                      aria-controls="kt_customers_table"
                      data-dt-idx={i}
                      tabIndex="0"
                      className="page-link"
                      value={i}
                    >
                      {i == 0 ? 'Prev' : ""}
                      {i != 0 && i != data.links.length - 1 ? item["label"] : ""}
                      {i == data.links.length - 1 ? 'Next' : ""}
                    </button>
                  </li>
                ))
                : ""}
            </ul>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
        // Swal.close();
    }
    return value;
}

handlePageChange(pageNumber){
  this.setState({
    currentPage:pageNumber
  })
}

  renderTab() {
    let currentPage = this.state.currentPage
    let items = [];

    if(this.state.fill == 0){
      this.state.listData = this.state.list
      this.state.fill = 1;
      }

    if(this.state.listData != null && this.state.listData != undefined){
      items = this.state.listData
        if(items.length > 0){
          const totalPages = Math.ceil(items.length / this.state.itemsPerPage);
          const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
          const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
          const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
          const startPage = Math.max(1, currentPage - Math.floor(this.state.maxPaginationItems / 2));
          const endPage = Math.min(totalPages, startPage + this.state.maxPaginationItems - 1);
          const adjustedStartPage = Math.max(1, endPage - this.state.maxPaginationItems + 1);
        return (
          <Fragment>
            <div className="col-md-12 mt-3"> 
              <table className="table table-responsive table-bordered table-hover"  style={{ borderBottom: '1px solid #eff2f5'}}>
                <thead>
                  <tr className="table-header" style={{ textAlign: 'center', color:'white'}}>
                    <th width={"3%"} >{i18n.t("No.")}</th>
                    <th width={"6%"} >{i18n.t("Code ")}</th>
                    <th width={"25%"} >{i18n.t("COA Name")}</th>
                    <th width={"11%"} >{i18n.t("Beginning Debt")}</th>
                    <th width={"11%"}  >{i18n.t("Beginning Credit")}</th>
                    <th width={"11%"}  >{i18n.t("Debt")}</th>
                    <th width={"11%"}  >{i18n.t("Credit")}</th>
                    <th width={"11%"}  >{i18n.t("Ending Debt")}</th>
                    <th width={"11%"}  >{i18n.t("Ending Credit")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderList(currentItems)}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-3">
              {/* {this.renderPagination()} */}
            </div>
          
          <br/>
          <div >
        
          </div>
          <div className="row mt-4">
              <Fragment>
                  <Pagination>
                        <Pagination.First onClick={() => this.handlePageChange(1)} disabled={currentPage === 1} />
                        <Pagination.Prev onClick={() => this.handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          
                        {Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => (
                          <Pagination.Item
                            key={adjustedStartPage + index}
                            active={adjustedStartPage + index === currentPage}
                            onClick={() => this.handlePageChange(adjustedStartPage + index)}
                          >
                            {adjustedStartPage + index}
                          </Pagination.Item>
                        ))}
          
                        <Pagination.Next onClick={() => this.handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                        <Pagination.Last onClick={() => this.handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                      </Pagination>
                </Fragment>
              </div>
          <div>
              <table className="table table-responsive table-bordered table-hover">
              <thead>
                <tr>
                  <th style={{fontSize:30,fontWeight:'bold'}}>
                  {i18n.t("Summary")}
                  </th>
                </tr>
                <tr style={{fontSize:24}}className="fw-semibold fs-16 text-gray-800  square ">
                    <th width={"15%"} >{i18n.t("Total Awal Debt")}</th>
                    <th width={"15%"} >{i18n.t("Total Awal Credit")}</th>
                    <th width={"15%"} >{i18n.t("Total Debt")}</th>
                    <th width={"15%"} >{i18n.t("Total Credit")}</th>
                    <th width={"15%"} >{i18n.t("Total Akhir Debt")}</th>
                    <th width={"15%"} >{i18n.t("Total Akhir Credit")}</th>
                </tr>
                </thead>
                <tbody>
                
                  {this.renderGrandTotal()}
                </tbody>
              </table>
          </div>
            </Fragment>
        );
      }
    }
  }

  render() {
    const { isProcessing, helperList, datalist, data } = this.props;
    console.log(this.props)
    this.state.periodList = helperList.workCalendar
    this.state.coa3List = helperList.coa3;
    this.state.branchList = helperList.branch;

    if(data != undefined && data != null){
      this.state.list = data
      if(this.state.bound == 0){
        this.state.fill = 0;
      }
    }

    
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
              <Tabs
                unmountOnExit
                defaultActiveKey="laporanWorkSheet"
                id="laporan"
                className="mb-3"
              >
                <Tab eventKey="laporanWorkSheet" title={i18n.t("Work Sheet Report")}>
                  <React.Fragment>
                    <div className="row">
                      <div className="col-mb-1">
                          <Radio
                            list={Period}
                            name={"type"}
                            title={""}
                            inline={false}
                            value={this.state.type}
                            handler={this.handlerRadio}
                          />
                        </div>
                      </div>
                      <div className="row">
                        {this.state.type == 1 ?
                          <Fragment>
                            <div className="col-md-4 mt-3">
                              <Select2
                                isClearable={true}
                                name={"period"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                  await this.handlerSelect(e, "idPeriod", "periodCurrent");
                                }}
                                options={this.state.periodList}
                                title={i18n.t("Period")}
                                value={this.state.periodCurrent}
                                placeholder={this.state.periodText}
                              />
                              <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('startDate')}
                              </div>
                            </div>
                          </Fragment>
                          :
                          <Fragment>
                            <div className="col-md-4 mt-3">
                              <DatePickerCustom
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                selectsRange={true}
                                isClearable={false}
                                name={"date"}
                                title={i18n.t("Date Period")}
                                required={true}
                                onChange={(e) => {
                                  const [start, end] = e;
                                  this.setState({ startDate: start, endDate: end });
                                }}
                                la
                                showYearDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                peekNextMonth
                                showMonthDropdown
                                autoComplete={"off"}
                                dateFormat="dd MMMM yyyy"
                                maxDate={new Date()}
                                className="form-control form-control-md"
                              />
                            </div>
                          </Fragment>
                        }
                        <div className="col-md-4 mt-3">
                          <Select2
                            isClearable={true}
                            name={"idBranch"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                              if (e == null) {
                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                              } else {
                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                              }
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            labelfield={"true"}
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-9 mt-3">
                            <Button onClick={async (e) => {
                              await this.setState({ page: 1 }, () => this.setData())
                              await this.setState({ headeTable: true, search:'',bound:0 })
                            }}
                              variant="success" className="btn btn-md btn btn-primary m-1 ">
                              <FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("  Show")} 
                            </Button>

                            <button className="btn btn-secondary dropdown-toggle m-1" type="button" id="dropdownMenuButton"
                              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Convert
                            </button>
                            <div className="dropdown-menu dropdown-menu-area" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item"
                                href={"/accounting/work-sheet/print/"+ (this.state.branchCurrent != null ? this.state.branchCurrent.value : 'all') +"/"+
                                (this.state.type == 2 ? moment(this.state.startDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.startDate)) +"/"+
                                (this.state.type == 2 ? moment(this.state.endDate).format("YYYY-MM-DD") : (this.state.periodCurrent == null?'':this.state.periodCurrent.endDate)) 
                                } target="_blank">
                                <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>  {i18n.t("PDF")}</a> 
                              <a className="dropdown-item " href="#" onClick={()=>this.convertExcel()}>
                                <FontAwesomeIcon icon={faFileExcel}>
                                </FontAwesomeIcon>  Excel 
                              </a>
                            </div>
                          </div>
                        
                          {this.state.headeTable == true?
                          <Fragment>
                          <div className="col-md-3 mt-3 ">
                            <input
                                type={"text"}
                                className="form-control form-control-md justify-content-right"
                                placeholder="Search"
                                name={"search"}
                                title={""}
                                value={this.state.search}
                                onChange = {(e)=>this.handlerSearch(e)}
                            />
                          </div>
                          {/* <div className="col-md-3 mt-3 ">
                            <input
                                type={"text"}
                                className="form-control form-control-md justify-content-right"
                                placeholder="Search"
                                name={"search"}
                                title={""}
                                value={this.state.search}
                                onChange = {(e)=>this.handlerSearch(e)}
                                onKeyUp={this._onEnter}
                                onBlur={this._onBlur}
                            />
                          </div> */}
                          </Fragment>:null}
                      </div>
                      
                        {this.state.headeTable == true ?
                          <React.Fragment>
                            {!isProcessing ?
                              this.renderTab()
                              :   isProcessing && this.state.onShow == false?
                              <div className="text-center">
                              <div className="spinner-border  text-primary text-center  clearfix"></div>
                              <div className="text-center">{i18n.t("Loading")}</div>
                              </div>
                              :null
                            }
                          </React.Fragment> :
                          ''}
                      </div>
                    
                  </React.Fragment>
                </Tab>

              </Tabs>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default WorkSheet;
