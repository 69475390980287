import React,{ Fragment, useState } from "react"
import { Table } from "react-bootstrap"
import i18n from "../../../../i18n";
import LabelTranscation from "../../../utilities/Status/transaction";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";

export default function DataPayment(data){
    let arr = data?.payment || [];
  
    // console.log('receiving',arr)
    return (
        // <div>Receiving Masuk</div>
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-5 px-0">
            {arr.length > 0 ? 
                <React.Fragment>
                 
               
                        
                    
                        {/* <div className="d-flex flex-column text-left mb-9 px-9">
                         <table>
                         <tbody className="text-gray-800 fw-bold fs-5">
                            <tr>
                                 <td>
                                     {i18n.t('No. Purchasing')}
                                 </td>
                                 <td>
                                     :
                                 </td>
                                 <td>
                                     {item.no_purchasing}
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                     {i18n.t('Date')}
                                 </td>
                                 <td>
                                     :
                                 </td>
                                 <td>
                                     {item.date}
                                 </td>
                             </tr>
                           
                            </tbody>
                        </table>
                    </div> */}
                     
                    <div className="d-flex flex-column text-left mb-9 px-9">
                        <div className="text-left">
                        <Table
                            className="table-responsive table-index"
                        >
                        <thead className="bg-success text-white text-center" >
                            <tr>
                                <th>{i18n.t("No.")}</th>
                                <th>{i18n.t("No. Payment")}</th>
                                <th>{i18n.t("No. Invoice")}</th>
                                <th>{i18n.t("Type Payment")}</th>
                                <th>{i18n.t("Total Invoice")}</th>
                                <th>{i18n.t("Total Payment")}</th>
                                <th>{i18n.t("Remaining")}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {arr?.map((detail,i)=>
                            <tr key={'detail'+i} >
                                <td className="text-gray-800 fw-bold fs-5" style={{textAlign:'center'}}>
                                    {i+1}
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.no_payment} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.no_invoice} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.payment} </span>
                                </td>
                                <td style={{textAlign:'right'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(detail.total_invoice,2)} </span>
                                </td>
                                <td style={{textAlign:'right'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(detail.total_payment,2)} </span>
                                </td>
                                <td style={{textAlign:'right'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {formatCurrency((detail.total_invoice - detail.total_payment),2)} </span>
                                </td>
                            
                            </tr>
                            )}
                        </tbody>
                        </Table>   
                    </div>
                </div> 
                <hr/>
                <br/>
                                                                                                                                 
                
                </React.Fragment>
                : <div className="text-center"><h2>{i18n.t("Not Have Data About Payment")}</h2></div>}
               
                    
                 
                
               
            </div>
            
        </div>
    )
}

