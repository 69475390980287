import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { RecapAttendance } from "../../../../components/modules/hr/recap-attendance";
import RecapAttendanceMiddleware from "../../../../middleware/modules/hr/recap-attendance";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ recapAttendance }) => ({
  isProcessing: recapAttendance.isProcessing,
  isError: recapAttendance.isError,
  errorMessage: recapAttendance.errorMessage,
  datalist: recapAttendance.data,
  helper:recapAttendance.helperRecapAttendance,
  tracking:recapAttendance.timeline,
  dataDetails:recapAttendance.details,
  loading:recapAttendance.loadingDetails,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper:()=>RecapAttendanceMiddleware.getHelper(),
      reset: () =>RecapAttendanceMiddleware.reset(),
      getTracking:(post)=>RecapAttendanceMiddleware.Tracking(post),
      getData:(type,id)=>RecapAttendanceMiddleware.getData(type,id)
    },
    dispatch
  );
};
class RecapAttendanceContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.reset();
    this.props.getHelper();
  }
  
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Recap Daily Attendance")}
          </title>
        </Helmet>
      <RecapAttendance
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecapAttendanceContainer);
