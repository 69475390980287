import React, { Component } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select2 from "../../../utilities/Form/Select2";

export class FormStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      dateRange: new Date(),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      loadingItem:false,
      itemCurrent: null,
      itemText: i18n.t("All"),
      itemList: [],
      loadingSupplier:false,
      warehouseCurrent: null,
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      data: [],
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.show = this.show.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
    }
    return i18n.t(value);
}

changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
  const token=cookieManager.getUserToken()
  let params=null
  switch(type){
    case "getItemBranch":
          var url = process.env.REST_URL + "helper/get-item-good-stock";
          var branch =
              this.state.branchCurrent != undefined
                  ? this.state.branchCurrent.value
                  : null;
          params = {
              branch: branch,
          };
          break;
  }

  if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

}

  show() {
    const params = {
      idBranch : this.state.branchCurrent != null ? this.state.branchCurrent.value : null,
      idItem : this.state.itemCurrent != null ? this.state.itemCurrent.value : null,
      limit : 25,
      page : 1
    }
    this.props.getData(params);
  }

  render() {
    const { helperList,onChoose, mutationShow } =
      this.props;
    if(helperList != null){
      this.state.branchList = (helperList.branch != undefined)?helperList.branch:[];
      this.state.supplierList = (helperList.supplier != undefined)?helperList.supplier:[];
    }

    return (
      <React.Fragment>
        <form>
          <div className="row">
            <div className="col-md-4 mt-3">
              <Select2
                  isClearable={true}
                  name={"idBranch"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                      await this.handlerSelect(e, "idBranch", "branchCurrent");
                      await this.setState({
                        loadingItem: true,
                      });
                      this.changeData({
                        keyFill: "itemList",
                        type: "getItemBranch",
                        key: "",
                        callback: () => { this.setState({ loadingItem: false }) }
                      })
                  }}
                  options={this.state.branchList}
                  title={i18n.t("Branch")}
                  value={this.state.branchCurrent}
                  placeholder={this.state.branchText}
                  labelfield={"true"}
              />
              <div className="fv-plugins-message-container invalid-feedback">
                  {this.renderError('idBranch')}
              </div>
            </div>
            {/* <div className="col-md-4 mt-3">
              <Select2
                  isClearable={true}
                  name={"idItem"}
                  required={true}
                  size={4}
                  onChange={async (e) => {
                      await this.handlerSelect(e, "idItem", "itemCurrent");
                  }}
                  options={this.state.itemList}
                  title={i18n.t("Item")}
                  value={this.state.itemCurrent}
                  placeholder={this.state.itemText}
                  labelfield={"true"}
                  isLoading={this.state.loadingItem}
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <Button
                variant="success"
                className="btn btn-sm"
                onClick={async () => {
                  await this.show();
                }}
              >
                {" "}
                {i18n.t("Show")}
              </Button>
            </div>
          </div>
          <hr />
        </form>
      </React.Fragment>
    );
  }
}

export default FormStock;
