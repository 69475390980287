import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import RecapAttendanceActions from "../../../../reducers/modules/hr/recap-attendance/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "report/recap-attendance";

export default class RecapAttendanceMiddleware {
  
  static reset() {
    return (dispatch) => {
      dispatch(RecapAttendanceActions.reset());
    };
  }
  static Tracking(post) {
    return (dispatch) => {
      dispatch(RecapAttendanceActions.process());
      RecapAttendanceMiddleware.showTracking(post,dispatch);
    };
  }
  
  static getHelper(params = null) {
    return (dispatch) => {
      RecapAttendanceMiddleware.showHelper(dispatch, params);
    };
  }

  
  static getToken() {
    return cookieManager.getUserToken();
  }

  static getData(type,id){
    console.log('LAHHHHH',type)
    console.log('WOOOY',id)
    return (dispatch) => {
      dispatch(RecapAttendanceActions.loadingDetails());
      RecapAttendanceMiddleware.showData({type:type,id:id},dispatch);
    };
  }

  static showData(params,dispatch){
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${RecapAttendanceMiddleware.getToken()}`,
      },
      url:process.env.REST_URL+'report/tracking-purchasing-supplier' ,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(RecapAttendanceActions.loadedDetails(response.data.result));
        }
      })
      .catch((error) => {
        RecapAttendanceMiddleware.alertHendler(dispatch, error);
      });
  }

  static showTracking(params,dispatch) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${RecapAttendanceMiddleware.getToken()}`,
      },
      url:process.env.REST_URL+'report/tracking-purchasing-show' ,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(RecapAttendanceActions.setTracking(response.data.result));
        }
      })
      .catch((error) => {
        RecapAttendanceMiddleware.alertHendler(dispatch, error);
      });
  }

  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${RecapAttendanceMiddleware.getToken()}`,
      },
      url:process.env.REST_URL+'helper/get-helper-purchasing-report' ,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(RecapAttendanceActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        RecapAttendanceMiddleware.alertHendler(dispatch, error);
      });
  }
 
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(RecapAttendanceActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(RecapAttendanceActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}
