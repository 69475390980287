
import React, { Component, Fragment } from 'react';
import { Button, Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Input from '../../../utilities/Form/input';
import Select2 from '../../../utilities/Form/Select2';
import axios from 'axios';
import InputNumber from '../../../utilities/Form/InputNumber';
import cookieManager from '../../../../utils/cookieManager';
import i18n from '../../../../i18n';
import Swal from 'sweetalert2';
import InputCustom from '../../../utilities/Form/InputCustom';

export class FormBill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                Billing: {},
                idBilling: {},
                BillingDetail: [],
                AddBilling: []
            },
            idBilling: null,
            branchCurrent: null,
            branchList: [],
            branchText: i18n.t('Select Branch'),
            storeCurrent: null,
            storeList: [],
            storeText: i18n.t('Select Store'),
            salesCurrent: null,
            salesList: [],
            salesText: i18n.t('Select Sales'),
            search: {
                limit: 10
            },
            set: 0,
            check: 1,
            transactionNo: '',
            show: false,
            onShow: false,
            idCustomer: '',
            chooseAll: 0,
            isUpdate: 0,
            isEdit: 0,
            date: new Date(),
            arrayPayment: [
            ],
            arrayDetail: [{

            }],
        }
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit = this.runSubmit.bind(this)
    }


    runSubmit() {
        const detail = this.state.arrayDetail;

        const Billing = {
            idBranch: this.state.branchCurrent == null ? '' : this.state.branchCurrent.value,
            idSales: this.state.salesCurrent == null ? '' : this.state.salesCurrent.value,
            date: moment(this.state.date).format('YYYY-MM-DD'),
            transactionNo: this.state.transactionNo == null ? '' : this.state.transactionNo,
            total: this.state.subTotal == null ? '' : this.state.subTotal,
        }

        let det = [];
        let detAdd = [];

        for (var i = 0; i < detail.length; i++) {
            if (detail[i].choose == true && detail[i].add == null) {
                det.push(detail[i]);
            } else {
                if (detail[i].add == true) {
                    detAdd.push(detail[i])
                }
            }
        }

        this.state.form.Billing = Billing;
        this.state.form.BillingDetail = det;

        if (this.state.isUpdate == 1) {
            this.state.form.Update = true;
            this.state.form.idBilling = this.state.idBilling;
            this.state.form.AddBilling = detAdd;
        }

        return this.state.form;
    }

    handlerDateTime(e, name) {
        var key = name;
        var value = e
        this.setState({
            [key]: value
        })
    }

    handler(e) {
        var key = e.target.name;
        var value = e.target.value
        this.setState({
            [key]: value
        })
    }

    handlerSelect(e, name, currentState) {
        var key = name
        var value = null
        if (e != null) {
            value = e.value
        }
        this.setState({
            ...this.state,
            [key]: value
        })
        this.setState({ [currentState]: e })
    }

    calculateAll() {
        const detail = this.state.arrayDetail.filter((item) => item.choose == true)
        let subTotal = 0
        let grandTotal = 0
        let refund = 0
        let payment = this.state.totalPayment
        let remaining = 0
        let totalRemaining = 0;
        detail.forEach((item, index) => {
            subTotal += parseFloat(item.remaining) || 0
        })

        totalRemaining = (parseFloat(grandTotal) || 0) - (parseFloat(payment) || 0);

        if (totalRemaining < 0) {
            refund = (parseFloat(payment) || 0) - (parseFloat(grandTotal) || 0)
            totalRemaining = 0
        }

        this.setState({
            subTotal: subTotal,
            refund: refund,
            payment: payment,
            grandTotal: grandTotal,
            totalRemaining: totalRemaining,
        })
    }

    handlerCurrencyArray(value, name, i, arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i];
        objectss[name] = value;
        this.setState(arr);
    }

    renderError(name) {
        const { isError, errorMessage } = this.props
        var value = ''
        if (isError) {
            if (errorMessage[name] != undefined) {
                value = errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }

    alertError(title, message, icon, i) {
        Swal.fire({
            title: title,
            html: message,
            icon: icon,
        });
    }

    choose(e, array, index, key) {
        const arr = [...this.state[array]];
        const objectss = arr[index]
        objectss[key] = !objectss[key];
        this.setState(arr, () => this.calculateAll())
    }

    chooseUpdate(e, array, index, key) {
        const arr = [...this.state[array]];
        const objectss = arr[index]
        objectss[key] = !objectss[key];
        if (this.state.isUpdate == 1) {
            objectss["add"] = !objectss["add"];
        }
        this.setState(arr, () => this.calculateAll())
    }

    chooseAll(e, array, key) {
        const arr = [...this.state[array]];
        if (this.state.chooseAll == 0) {
            for (var i = 0; i < arr.length; i++) {
                const objectss = arr[i]
                objectss[key] = true;
                this.state.chooseAll = 1;
            }
        } else {
            for (var i = 0; i < arr.length; i++) {
                const objectss = arr[i]
                objectss[key] = false;
                this.state.chooseAll = 0;
            }
        }
        this.setState(arr, () => this.calculateAll())
    }

    setList() {
        var date = moment(this.state.date).format("YYYY-MM-DD");
        var idBranch = (this.state.branchCurrent == null) ? '' : this.state.branchCurrent.value;
        var idStore = (this.state.storeCurrent == null) ? '' : this.state.storeCurrent.value;
        var idSales = (this.state.salesCurrent == null) ? '' : this.state.salesCurrent.value;

        let params = {
            date: date,
            idStore: idStore,
            idBranch: idBranch,
            idSales: idSales,
        }
        this.props.setListBill(params);
    }


    renderList() {
        const { dataUpdate, dataBilling, isProcessing, isError, errorMessage } = this.props;
        let html = [];
        let row = [];


        if (dataBilling != null && dataBilling != '' && dataUpdate == null) {
            row = dataBilling
            this.state.arrayDetail = dataBilling;
            html.push(
                <React.Fragment key={'form'}>
                    <form>
                        <div className='row'>
                            <div className="col-12">
                                <Table  >
                                    <thead>
                                        <tr key={'trTable'} className="fw-semibold fs-6 text-gray-800 bg-aquamarine border" style={{ fontWeight: 'bold', fontSize: '16', textAlign: 'center' }} >
                                            <th style={{ textAlign: 'center' }}>{i18n.t(" No.")}</th>
                                            <th >{i18n.t("Invoice No")}</th>
                                            <th >{i18n.t("Customer Name")}</th>
                                            <th >{i18n.t("Date")}</th>
                                            <th >{i18n.t("Due Date")}</th>
                                            <th >{i18n.t("Total Invoice")}</th>
                                            <th >{i18n.t("Remaining Payment")}</th>
                                            <th >{i18n.t("Payment")}</th>
                                            <th >
                                                <InputCustom
                                                    type={"checkbox"}
                                                    onChange={(e) => this.chooseAll(e, 'arrayDetail', 'choose')}
                                                    className={
                                                        "form-check-input form-check form-check-custom form-check-solid"
                                                    }
                                                    name={"choseAll"}
                                                    labelfield={"false"}
                                                /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(row.length > 0) ?
                                            row.map((detail, index) => {
                                                return this.renderDetail(detail, index);
                                            })
                                            : null}
                                    </tbody>
                                    <tfoot>

                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="d-flex flex-end">
                                <div className='col-4'>
                                    <Table borderless responsive="sm">
                                        <tbody>
                                            <tr className="align-middle">
                                                <td>{i18n.t("Total Bill")}</td>
                                                <td>:</td>
                                                <td>
                                                    <InputNumber
                                                        id={"subTotal"}
                                                        name="subTotal"
                                                        placeholder={i18n.t("Total Bill")}
                                                        defaultValue={0}
                                                        value={this.state.subTotal}
                                                        decimalsLimit={2}
                                                        onValueChange={(value, name) => { }}
                                                        className='p-0 form-control form-control-xs form-control-flush text-end'
                                                        prefix='Rp '
                                                        readOnly
                                                        labelfield={"false"}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            );
        } else if (dataUpdate != null) {
            let row = this.state.arrayDetail
            console
            html.push(
                this.state.show == true ?
                    <React.Fragment key={'form'}>
                        {(!isProcessing) ?
                            <form>
                                <div className='row'>
                                    <div className="col-12">
                                        <Table  >
                                            <thead>
                                                <tr key={'trTable'} className='border' style={{ fontWeight: 'bold', fontSize: '16', textAlign: 'center' }}>
                                                    <th >{i18n.t("No.")}</th>
                                                    <th >{i18n.t("Invoice No")}</th>
                                                    <th >{i18n.t("Customer Name")}</th>
                                                    <th >{i18n.t("Date")}</th>
                                                    <th >{i18n.t("Due Date")}</th>
                                                    <th >{i18n.t("Total Remaining")}</th>
                                                    <th >{i18n.t("Total Payment")}</th>
                                                    <th >{i18n.t("Remaining")}</th>
                                                    {dataUpdate.status == 1 ?
                                                        <th >
                                                            {i18n.t("Status")}
                                                        </th> :
                                                        null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(row.length > 0) ?
                                                    row.map((detail, index) => {
                                                        return this.renderDetail(detail, index);
                                                    })
                                                    : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="d-flex flex-end">
                                        <div className='col-4'>
                                            <Table borderless responsive="sm">
                                                <tbody>
                                                    <tr className="align-middle">
                                                        <td>{i18n.t("Total Bill")}</td>
                                                        <td>:</td>
                                                        <td>
                                                            <InputNumber
                                                                id={"subTotal"}
                                                                name="subTotal"
                                                                placeholder={i18n.t("Total Bill")}
                                                                defaultValue={0}
                                                                value={this.state.subTotal}
                                                                decimalsLimit={2}
                                                                onValueChange={(value, name) => { }}
                                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                                prefix='Rp '
                                                                readOnly
                                                                labelfield={"false"}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            : isProcessing && this.state.onShow == false ?
                                <div className="text-center">
                                    <div className="spinner-border  text-primary text-center  clearfix"></div>
                                    <div className="text-center">{i18n.t("Loading")}</div>
                                </div>
                                : null}
                    </React.Fragment> : null
            );
        } else {
            if (isError && this.state.check == 0) {
                this.alertError('Info', errorMessage, 'info')
                this.state.check = 1;
            } else {

                html.push(
                    this.state.show == true && !isError ?
                        <tr>
                            <td colSpan={'5'} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20' }}>{i18n.t('NO DATA FOUND')}</td>
                        </tr>
                        : null
                );

            }
            // this.alertError('asd')

        }
        return html;

    }

    renderDetail(detail, i) {
        return <React.Fragment>
            <tr key={"detail" + i} className={(detail.choose == true) ? 'custom-opacity-bg ' : ''} >
                <td align='center'>
                    {i + 1 + '.'}
                </td>
                <td>
                    {detail.transaction_no}
                </td>
                <td>
                    {detail.customer_name}
                </td>
                <td>
                    {detail.date}
                </td>
                <td>
                    {detail.due_date}
                </td>
                <td align='right'>
                    {detail.total_format}
                </td>
                <td align='right'>
                    {detail.rem_format}
                </td>
                <td align='right'>
                    {detail.payment_format}
                </td>

                {this.state.isUpdate == 1 ?
                    <td align='center'>
                        {detail.status == 2 ? 'Paid' : ''}
                    </td> : null}
                <td align='center'>
                    <InputCustom
                        type={"checkbox"}
                        className={
                            "form-check-input form-check form-check-custom form-check-solid"
                        }
                        key={"choose" + i}
                        disabled={detail.status == 2 || detail.status == 3 ? true : false}
                        value={detail.choose}
                        checked={detail.choose ? true : false}
                        name={"chose"}
                        labelfield={"false"}
                        onChange={this.state.isUpdate == 0 ?
                            (e) => this.choose(e, 'arrayDetail', i, 'choose') :
                            (e) => this.chooseUpdate(e, 'arrayDetail', i, 'choose')
                        }

                    />
                </td>
            </tr>
        </React.Fragment>
    }

    changeData(
        keyFill,
        type = null,
        key = "",
        array = null,
        arraykey = null,
        extraFunction = null
    ) {
        const token = cookieManager.getUserToken();
        let params = null;
        switch (type) {
            case "getStore":
                var url = process.env.REST_URL + "helper/get-store";
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    id: branch,
                };
                break;
            case "getEmployeeSales":
                var url = process.env.REST_URL + "helper/get-employee-sales";
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                params = {
                    branch: branch,
                };
                break;
            case "getCode":
                var url = process.env.REST_URL + "helper/get-code-billing";
                var date =
                    this.state.date != undefined
                        ? moment(this.state.date).format("YYYY-MM-DD")
                        : null;
                var branch =
                    this.state.branchCurrent != null
                        ? this.state.branchCurrent.value
                        : null;
                var sales =
                    this.state.salesCurrent != null
                        ? this.state.salesCurrent.value
                        : null;
                params = {
                    date: date,
                    branch: branch,
                    sales: sales,
                };
                break;
        }

        if (type != null) {
            axios({
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                url: url,
                responseType: "json",
                params: params,
            })
                .then((response) => {
                    if (response && response.status === 200 && response.data) {
                        if (array != null) {
                            var arr = [...this.state[array]];
                            const objectss = arr[arraykey];
                            if (key == "") {
                                objectss[keyFill] = response.data.result;
                            } else {
                                objectss[keyFill] = response.data.result[key];
                            }
                        } else {
                            if (key == "") {
                                var arr = { [keyFill]: response.data.result };
                            } else {
                                var arr = { [keyFill]: response.data.result[key] };
                            }
                        }
                        if (extraFunction != null) {
                            this.setState(arr, extraFunction);
                        } else {
                            this.setState(arr);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);

                });
        }
    }


    render() {
        const { dataUpdate, helperBilling, isProcessing, dataBilling, isError, errorMessage } = this.props
        if (dataUpdate != null) {
            let data = dataUpdate
            if (this.state.isUpdate == 0) {
                this.state.idBilling = data.id
                this.state.transactionNo = data.transaction_no,
                    this.state.date = new Date(data.date)
                this.state.branchCurrent = { value: data.id_branch, label: data.name_branch }
                this.state.salesCurrent = { value: data.id_sales, label: data.name_sales }
                this.state.arrayDetail = []
                var detail = data.detail
                for (var po = 0; po < data.detail.length; po++) {
                    this.state.arrayDetail.push(
                        {
                            id: detail[po].id,
                            transaction_no: detail[po].transaction_no,
                            customer_name: detail[po].customer_name,
                            id_customer: detail[po].id_customer,
                            date: detail[po].date,
                            due_date: detail[po].due_date,
                            total: detail[po].total,
                            total_payment: detail[po].total_payment,
                            payment_type: detail[po].payment_type,
                            remaining: detail[po].remaining,
                            total_format: detail[po].total_format,
                            payment_format: detail[po].payment_format,
                            remaining_format: detail[po].remaining_format,
                            rem_format: detail[po].rem_format,
                            status: detail[po].status,
                            choose: detail[po].status == 3 || detail[po].status == 2 ? true : false

                        }
                    )
                }
                this.calculateAll();
                this.setState({ show: true, isUpdate: 1 }, () => this.renderList())
            }
        }

        if (helperBilling != null) {
            this.state.branchList = helperBilling.branch;
        }

        return (
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                <div className="flex-row-fluid" id="kt_content">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-2 mt-3">
                                    <Input
                                        type={"input"}
                                        name={"transactionNo"}
                                        title={i18n.t("Transaction No")}
                                        required
                                        disabled
                                        value={this.state.transactionNo}
                                        handler={this.handler}
                                    />
                                </div>
                                <div className="col-sm-2 mt-3">
                                    <DatePickerCustom
                                        name={"date"}
                                        title={i18n.t("Date")}
                                        required={true}
                                        selected={this.state.date}
                                        onSelect={async (e) => {
                                            await this.handlerDateTime(e, 'date');
                                            await this.changeData('paymentNo', 'getCode', 'code');
                                        }
                                        }
                                        disabled={this.state.isUpdate == 1 ? true : false}
                                        onChange={(e) => { }}
                                        startDay={0}
                                        autoComplete={"off"}
                                        dateFormat="dd MMMM yyyy"
                                        maxDate={new Date()}
                                        minDate={this.state.limitDate}
                                        className="form-control form-control-sm"
                                    />
                                </div>
                                <div className="col-sm-2 mt-3">
                                    <Select2
                                        isClearable={true}
                                        name={"idBranch"}
                                        required={true}
                                        size={4}
                                        isDisabled={this.state.isUpdate == 1 ? true : false}
                                        onChange={async (e) => {
                                            this.setState({
                                                transactionNo: '', salesCurrent: null,
                                                salesList: [], idSales: '',
                                                storeCurrent: null, storeList: [], idStore: ''
                                            });
                                            if (e == null) {
                                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                await this.setState({ salesCurrent: null, salesList: [], storeCurrent: null, storeList: [] });
                                            } else {
                                                await this.handlerSelect(e, "idBranch", "branchCurrent");
                                                await this.changeData('salesList', 'getEmployeeSales', '');
                                                await this.changeData('storeList', 'getStore', '');
                                                await this.changeData('transactionNo', 'getCode', 'code');
                                            }
                                        }}
                                        options={this.state.branchList}
                                        title={i18n.t("Branch")}
                                        value={this.state.branchCurrent}
                                        placeholder={this.state.branchText}
                                        labelfield={"true"}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {this.renderError("Billing.idBranch")}
                                    </div>
                                </div>

                                <div className="col-sm-2 mt-3">
                                    <Select2
                                        isClearable={true}
                                        name={"idStore"}
                                        size={4}
                                        isDisabled={this.state.isUpdate == 1 ? true : false}
                                        onChange={async (e) => {
                                            this.setState({ storeCurrent: null, storeList: [] });
                                            await this.handlerSelect(e, "idStore", "storeCurrent");
                                        }}
                                        options={this.state.storeList}
                                        title={i18n.t("Store")}
                                        value={this.state.storeCurrent}
                                        placeholder={this.state.storeText}
                                        labelfield={"true"}
                                    />
                                </div>
                                <div className="col-sm-2 mt-3">
                                    <Select2
                                        isClearable={true}
                                        name={"idSales"}
                                        required={true}
                                        isDisabled={this.state.isUpdate == 1 ? true : false}

                                        size={4}
                                        onChange={async (e) => {
                                            this.setState({ transactionNo: null })
                                            if (e == null) {
                                                await this.handlerSelect(e, "idSales", "salesCurrent");

                                            } else {
                                                await this.handlerSelect(e, "idSales", "salesCurrent");

                                            }
                                        }}
                                        options={this.state.salesList}
                                        title={i18n.t("Sales")}
                                        value={this.state.salesCurrent}
                                        placeholder={this.state.salesText}
                                        labelfield={"true"}
                                    />

                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {this.renderError("Billing.idSales")}
                                    </div>
                                </div>
                                <div className="col-sm-2 mt-9">
                                    <Button onClick={async (e) => {
                                        await this.setState({ show: true, check: 0, chooseAll: 0 }, () => this.setList())
                                    }}
                                        variant="success" className="btn btn-sm btn btn-success m-3 ">
                                        {i18n.t("  Show")} </Button>
                                </div>
                            </div>
                            <div className='row'>
                            </div>
                            <hr />
                            <div className='row'>
                                <Fragment>
                                    {(!isProcessing) ?
                                        this.renderList() :
                                        <div className="text-center">
                                            <div className="spinner-border  text-primary text-center  clearfix"></div>
                                            <div className="text-center">{i18n.t("Loading")}</div>
                                        </div>}
                                </Fragment>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default FormBill;