import React,{ useState } from "react"
import { Table } from "react-bootstrap"
import i18n from "../../../../i18n";
import { formatCurrency, formatNumber } from "../../../utilities/Class/FormatNumber";
import LabelTranscation from "../../../utilities/Status/transaction";

export default function DataOrder(props){
    let arr = props?.order || [];
    // const post={
    //     startDate:moment(data?.startDate).format('YYYY-MM-DD'),
    //     endDate:moment(data?.endDate).format('YYYY-MM-DD'),
    //     idBranch:data.branchCurrent ? data.branchCurrent.value : '',
    //   }
    console.log('order',arr)
    return (
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-5 px-0">
                <div className="d-flex flex-column text-left mb-9 px-9">
                   <table>
                    <tbody className="text-gray-800 fw-bold fs-5">
                        <tr>
                            <td>
                                {i18n.t('Date')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.date}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('No. PO')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.no_po}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('Total')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {'Rp. ' + formatCurrency(arr.total,2)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('Status')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <LabelTranscation value={arr.status}></LabelTranscation>
                            </td>
                        </tr>
                    </tbody>
                   </table>
                </div>
                <div className="d-flex flex-column text-left mb-9 px-9">
                  
                    <div className="text-left">
                    <Table
                    className="table-responsive bordered"
                >
                    <thead className="bg-success text-white text-center" >
                        <tr>
                            <th>{i18n.t("No.")}</th>
                            <th>{i18n.t("Name Item")}</th>
                            <th>{i18n.t("Price")}</th>
                            <th>{i18n.t("Qty")}</th>
                            {/* <th>{i18n.t("Receiving")}</th>
                            <th>{i18n.t("Remaining")}</th> */}
                            <th>{i18n.t("Total")}</th>
                            
                        </tr>
                    </thead>
                    <tbody>

                        {arr.detail?.map((item,index)=>
                        <tr key={'detail'+index} style={{border:'2px'}}>
                            <td className="text-gray-800 fw-bold fs-5" style={{textAlign:'center'}}>
                                {index+1}
                            </td>
                            <td style={{textAlign:'center'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {item.name_item} </span>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(item.price,2)} </span>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {formatNumber(item.qty)} </span>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(item.total,2)} </span>
                            </td>
                        </tr>
                        )}
                    </tbody>
               </Table>
                        
                        
                        
            </div>
                    
                </div>
            </div>
            
        </div>
    )
}