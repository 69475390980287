import React,{ useState } from "react"
import { Table } from "react-bootstrap"
import i18n from "../../../../i18n";

export default function DataSupplier(data){
    let arr = data?.supplier || [];
    const post={
        startDate:moment(data?.startDate).format('YYYY-MM-DD'),
        endDate:moment(data?.endDate).format('YYYY-MM-DD'),
        idBranch:data.branchCurrent ? data.branchCurrent.value : '',
      }
    return (
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-15 px-0">
                
                <div className="d-flex flex-column text-left mb-9 px-9">
                    <div className="text-left">
                    <Table
                    className="table-responsive bordered"
                >
                    <thead className="bg-success text-white text-center" >
                        <tr>
                            <th>{i18n.t("No.")}</th>
                            <th>{i18n.t("Supplier Name")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {arr.map((item,index)=>
                        <tr key={'supllier'+index} >
                            <td className="text-gray-800 fw-bold fs-4" style={{textAlign:'center'}}>
                                {index+1}
                            </td>
                            <td style={{textAlign:'center'}}>
                            <span
                            style={{
                                cursor: "pointer",
                            }}
                             onClick={()=>{
                                
                                // data.getData('getOrder')
                                data.updateShow(true,item.id)
                            }} className="text-gray-800 fw-bold text-hover-primary fs-4"> {item.name} </span>
                            </td>
                        </tr>
                        )}
                    </tbody>
               </Table>
                        
                        
                        {/* <span className="text-muted d-block fw-semibold">{data?.positionName||''}</span> */}
                        
            </div>
                    
                </div>
            </div>
            
        </div>
    )
}