import React,{ Fragment, useState } from "react"
import { Table } from "react-bootstrap"
import i18n from "../../../../i18n";
import LabelTranscation from "../../../utilities/Status/transaction";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";

export default function DataReceiving(data){
    let arr = data?.receiving || [];
    return (
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-5 px-0">
            {arr.length > 0 ? 
                <React.Fragment>
                 
                 {arr.map((item,i)=>{
                     return(
                     <Fragment key={'rec'+i}>
                        <div className="d-flex flex-column text-left mb-9 px-9">
                         <table>
                         <tbody className="text-gray-800 fw-bold fs-5">
                            <tr>
                                 <td>
                                     {i18n.t('No. Receiving')}
                                 </td>
                                 <td>
                                     :
                                 </td>
                                 <td>
                                     {item.no_receiving}
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                     {i18n.t('Date')}
                                 </td>
                                 <td>
                                     :
                                 </td>
                                 <td>
                                     {item.date}
                                 </td>
                             </tr>
                             <tr>
                                 <td>
                                     {i18n.t('Delivery Date')}
                                 </td>
                                 <td>
                                     :
                                 </td>
                                 <td>
                                     {item.delivery_date}
                                 </td>
                             </tr>
                         </tbody>
                 </table>
                 
                 </div>
                     {/* </Fragment>
                    <Fragment> */}
                     
                    <div className="d-flex flex-column text-left mb-9 px-9">
                        <div className="text-left">
                        <Table
                            className="table-responsive table-index"
                        >
                        <thead className="bg-success text-white text-center" >
                            <tr>
                                <th>{i18n.t("No.")}</th>
                                <th>{i18n.t("Code Item")}</th>
                                <th>{i18n.t("Name Item")}</th>
                                <th>{i18n.t("Unit")}</th>
                                <th>{i18n.t("No. Band")}</th>
                                <th>{i18n.t("Warehouse")}</th>
                                <th>{i18n.t("Qty Order")}</th>
                                <th>{i18n.t("Qty Receiving")}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {item.detail?.map((detail,i)=>
                            <tr key={'detail'+i} >
                                <td className="text-gray-800 fw-bold fs-5" style={{textAlign:'center'}}>
                                    {i+1}
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.item_code} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.item_name} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.unit_name} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.bandNo} </span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {detail.name_warehouse} </span>
                                </td>
                                <td style={{textAlign:'right'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(detail.qty_order,0)} </span>
                                </td>
                                <td style={{textAlign:'right'}}>
                                    <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(detail.qty,0)} </span>
                                </td>
                            
                            </tr>
                            )}
                        </tbody>
                        </Table>   
                    </div>
                </div> 
                <hr/>
                <br/>
                </Fragment>
                
                     )}
                    
                    )}                                                                                                               
                
                </React.Fragment>
                : <div className="text-center"><h2>{i18n.t("Not Have Reception")}</h2></div>}
               
                    
                 
                
               
            </div>
            
        </div>
    )
}

