import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import Select2 from "../../../utilities/Form/Select2";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import { Button, Table } from "react-bootstrap";
import cookieManager from "../../../../utils/cookieManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import DataSupplier from "./supplier";
import SalesInvoiceReportMiddleware from "../../../../middleware/modules/report/sales-report";
import DataOrder from "./order";
import DataRequest from "./request";
import DataReceiving from "./receiving";
import DataPurchasing from "./purchasing";
import LabelTranscation from "../../../utilities/Status/transaction";
import DataPayment from "./payment";
const MySwal = withReactContent(Swal);
export class RecapAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Report"), class: " text-gray-600" },
        { link: null, title: i18n.t("Tracking Purchasing"), class: " text-gray-500" },
      ],
      idSupplier:null,
      supplierList:[],
      supplierText:'All',
      supplierCurrent:null,
      idBranch: null,
      branchCurrent: null,
      branchText: "Select Branch",
      branchList: [],
      loadingStore:false,
      loadingEmployee:false,
      idStore: null,
      storeCurrent: null,
      storeText: "Select Store",
      storeList: [],
      idEmployee: null,
      employeeCurrent: null,
      employeeText: "Select Employee",
      employeeList: [],
      date:new Date(),
      startDate:new Date('2025-01-01'),
      endDate:new Date('2025-02-06'),
      progressConvert:false,
      showData:false,
      showDataDetail:false,
      transactionList:null,
      orderList:null,
      loadingData:false,
      loadingDataDetail:false,
    }
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime=this.handlerDateTime.bind(this);
    this.updateShow=this.updateShow.bind(this);
    this.updateShowDetail=this.updateShowDetail.bind(this);
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
        [key]: value,
        [currentState]: e,
      }
    );
  }

  updateShow(newShow,id) {
    this.setState({showDataDetail:false, showData: newShow, idSupplier:id,loadingData:true },()=>this.getData('getTransaction'));
  }

  updateShowDetail(newShow,id) {
    this.setState({ showDataDetail: newShow, idPO:id,loadingDataDetail:true },()=>this.getData('getOrder'));
  }
  
  getData(type,extra=()=>{}) {
    const token = cookieManager.getUserToken();
    let params = null;
    let fill = null;
    let method = null;
    console.log('masuk sini')
    switch (type) {
      case "getTransaction":
        fill = "transactionList";
        method = "POST";
        var url = process.env.REST_URL + 'report/tracking-purchasing-supplier';
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        var supplier =  this.state.idSupplier != undefined
            ? this.state.idSupplier
            : null;
        var startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        var endDate = moment(this.state.endDate).format('YYYY-MM-DD');
         
        if (branch != null ) {
          params = {
            idBranch: branch,
            idSupplier: supplier,
            startDate: startDate,
            endDate: endDate,
          };
        }
        

        break;
        case "getOrder":
        fill = "orderList";
        method = "POST";
        var url = process.env.REST_URL + 'report/tracking-purchasing-po';
        var branch =
          this.state.branchCurrent != undefined
            ? this.state.branchCurrent.value
            : null;
        var supplier =  this.state.idSupplier != undefined
            ? this.state.idSupplier
            : null;
        var po =  this.state.idPO != undefined
            ? this.state.idPO
            : null;    
        var startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        var endDate = moment(this.state.endDate).format('YYYY-MM-DD');
         
        if (branch != null ) {
          params = {
            idBranch: branch,
            idSupplier: supplier,
            startDate: startDate,
            endDate: endDate,
            idPO: po,
          };
        }
        

        break;
      }
    //   case "getEmployee":
    //     fill = "employeeList";
    //     var url = process.env.REST_URL + "helper/get-employee";
    //     var branch =
    //       this.state.branchCurrent != undefined
    //         ? this.state.branchCurrent.value
    //         : null;
    //     var store =
    //       this.state.storeCurrent != undefined
    //         ? this.state.storeCurrent.value
    //         : null;
    //     if (branch != null || store != null) {
    //       params = {
    //         branch: branch,
    //         store: store,
    //       };
    //     }

    //     break;
    // }
    switch(method){
      case "POST":
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${SalesInvoiceReportMiddleware.getToken()}`
          },
          url: url,
          data:params,
          responseType: 'json'
        })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
              this.setState({ [fill]: response.data.result , loadingData: false ,loadingDataDetail:false},extra);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      break;
    }

    // if (type != null && params != null) {
    //   axios({
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //     url: url,
    //     responseType: "json",
    //     params: params,
    //   })
    //     .then((response) => {
    //       if (response && response.status === 200 && response.data) {
    //         this.setState({ [fill]: response.data.result },extra);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  }
  handlerDateTime(e,name) {
      var key=name;
      var value=e
      this.setState({
          [key]:value
      })
  }

  renderLoading() {
      Swal.fire({
        title: 'Loading',
        width: "200px",
        heightAuto: true
      });
      Swal.showLoading();
    }
  alertHandler(title, message, icon) {
      Swal.fire({
        title: title,
        html: message,
        icon: icon,
      });
    }
  async convertExcel() {
    if(this.state.employeeCurrent !=null){
      this.setState({progressConvert:true})

      const params={
        date:moment(this.state.date).format('YYYY-MM-DD'),
        idEmployee:this.state.idEmployee,
      }

      var salesName=this.state.employeeCurrent?.label
      var nameFile = 'Report Visiting '+(moment(this.state.date).format('DD MM YYYY'))+' Sales '+salesName ;
       
      this.renderLoading();
      const token = cookieManager.getUserToken();
      axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: process.env.REST_URL + "sales/report-visit-excel",
        method: "GET",
        params: params,
        responseType: "blob",
      }).then((response) => {
        if (response && response.status === 200 && response.data) {
          Swal.close();
          this.setState({progressConvert:false})
          this.alertHandler('Convert Excel', 'Download Successfully', 'success')
          var urlCreator = window.URL || window.webkitURL;
          const blobUrl = urlCreator.createObjectURL(response.data);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = blobUrl;
          link.setAttribute("download",
            nameFile +'.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          Swal.close();
        }
      }).catch((error) => {
        this.setState({progressConvert:false})
        this.alertHandler('Convert Excel', 'Internal Server Error', 'info')
      });
    }
      
  }

  showTracking(){
    const post={
      startDate:moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate:moment(this.state.endDate).format('YYYY-MM-DD'),
      idBranch:this.state.branchCurrent ? this.state.branchCurrent.value : '',
      idSupplier:this.state.supplierCurrent ? this.state.supplierCurrent.value : '',
    }

    if(this.state.idBranch !=null){
      this.props.getTracking(post)
    }
  }

  render() {
    const { helper,tracking,isProcessing} = this.props;
    console.log(isProcessing)
    if (helper != null && helper != undefined) {
      this.state.branchList = helper.branch;
      this.state.supplierList = helper.supplier;
    }
    console.log('PROPS', this.props)
    console.log('STATE', this.state)
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
           
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-3">
                <div className="row mb-4">
                <div className="col-sm-3">
                    <DatePickerCustom
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      selectsRange={true}
                      isClearable={false}
                      name={"date"}
                      title={i18n.t("Date Period")}
                      required={true}
                      onChange={(e) => {
                        const [start, end] = e;
                        this.setState({ startDate: start, endDate: end });
                      }}
                      la
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      peekNextMonth
                      showMonthDropdown
                      autoComplete={"off"}
                      dateFormat="dd MMMM yyyy"
                      maxDate={new Date()}
                      className="form-control form-control-md"
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      name={"idBranch"}
                      required={true}
                      onChange={async (e) => {
                        if (e != this.state.branchCurrent) {
                          // await this.setState({
                          //   storeList: [],
                          //   storeCurrent: null,
                          //   idStore: null,
                          //   employeeList: [],
                          //   employeeCurrent: null,
                          //   idEmployee: null,
                          //   loadingStore:true,
                          //   loadingEmployee:true,
                          // });
                          await this.handlerSelect(
                            e,
                            "idBranch",
                            "branchCurrent"
                          );
                          // await this.getData("getStore",()=>{
                          //   this.setState({ loadingStore:false})
                          // });
                          // await this.getData("getEmployee",()=>{
                          //   this.setState({ loadingEmployee:false})
                          // });
                        }
                      }}
                      options={this.state.branchList}
                      title={i18n.t("Branch")}
                      value={this.state.branchCurrent}
                      currentState={"branchCurrent"}
                      placeholder={this.state.branchText}
                      error={this.renderError("idBranch")}
                    />
                  </div>
                  {/* <div className="col-sm-3">
                    <Select2
                      isClearable={true}
                      isLoading={this.state.loadingStore}
                      name={"idStore"}
                      required={false}
                      onChange={async (e) => {
                        if (e != this.state.storeCurrent) {
                          await this.setState({
                            employeeList: [],
                            employeeCurrent: null,
                            idEmployee: null,
                            loadingEmployee:true,
                          });
                          await this.handlerSelect(
                            e,
                            "idStore",
                            "storeCurrent"
                          );
                          await this.getData("getEmployee",()=>{
                            this.setState({ loadingEmployee:false})
                          });
                        }
                      }}
                      options={this.state.storeList}
                      title={i18n.t("Store")}
                      value={this.state.storeCurrent}
                      currentState={"storeCurrent"}
                      placeholder={this.state.storeText}
                      error={this.renderError("idStore")}
                    />
                  </div> */}
                  <div className="col-sm-3">
                    <Select2
                            isClearable={true}
                            name={"supplier"}
                            required={true}
                            size={4}
                            onChange={async (e) => {
                                if (e == null) {
                                    await this.handlerSelect(e, "idSupplier", "supplierCurrent");
                                } else {
                                    await this.handlerSelect(e, "idSupplier", "supplierCurrent");
                                }
                            }}
                            options={this.state.supplierList}
                            title={i18n.t("Supplier")}
                            value={this.state.supplierCurrent}
                            placeholder={this.state.supplierText}
                            labelfield={"true"}
                        />
                        <div className="fv-plugins-message-container invalid-feedback">
                            {this.renderError("idCoa4")}
                        </div>
                  </div>
                 
                  
                  <div className="col-sm-6 d-flex gap-5">
                    <Button
                      onClick={(e) => {
                        this.setState({showData:false,showDataDetail:false});
                        this.showTracking();
                      }}
                      // disabled={
                      //   isProcessing
                      // }
                      variant="success"
                      className="btn btn-sm btn btn-success mt-8 "
                    >
                      {isProcessing ? 
                        <span className="indicator-progress " >{i18n.t("Please wait")}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                        : 
                        <span className="indicator-label"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {i18n.t("Show")}</span>
                      }
                      
                    </Button>
                    {/* <Button
                      onClick={(e) => {
                        this.convertExcel();
                      }}
                      disabled={
                        this.state.progressConvert
                      }
                      variant="success"
                      className="btn btn-sm btn btn-success mt-8"
                    >
                      {this.state.progressConvert ? 
                        <span className="indicator-progress " >{i18n.t("Please wait")}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span> 
                        : 
                        <span className="indicator-label"><FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon> {i18n.t("Export to Excel")}</span>
                      }
                      
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(tracking && !isProcessing) &&<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
						
						<div className="content flex-row-fluid" id="kt_content">
							
							<div className="d-flex flex-row">
							
								<div className="d-lg-flex flex-column flex-lg-row-auto w-lg-500px" data-kt-drawer="true" data-kt-drawer-name="start-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '250px': '300px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_social_start_sidebar_toggle">
									
									<DataSupplier
                    {...tracking}
                    {...this.state}
                    {...this.props}
                    updateShow = {this.updateShow}
                  >

                  </DataSupplier>
								</div>
          
							{this.state.showData  ?
								<div className="w-100 flex-lg-row-fluid mx-lg-13">
									
									<div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
										<div className="d-flex align-items-center gap-2">
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_start_sidebar_toggle">
												<i className="ki-duotone ki-profile-circle fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_end_sidebar_toggle">
												<i className="ki-duotone ki-scroll fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
										</div>
									</div>
									
									
									<div className="card">
										
										<div className="card-header card-header-stretch " style={{justifyContent:'left'}}>
											
											<div className="card-title d-flex align-items-center">
												<i className="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
													<span className="path4"></span>
													<span className="path5"></span>
													<span className="path6"></span>
												</i>
												{/* <h3 className="fw-bold m-0 text-gray-800">{(timeline?.date)?moment(timeline?.date).format('MMMM DD, YYYY'):''}</h3> */}
											</div>
											<div className="card-toolbar m-0">
												<ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
													<li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#transaction">{i18n.t("Transaction")}</a>
													</li>
												</ul>
											</div>
										</div>
										
										<div className="card-body">
                      <div className="tab-content">
                        <div id="transaction" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="timeline">
                        <React.Fragment>
                          <div className={this.state.loadingData ? " table-loading" : ""}>
                          {!this.state.loadingData ? (
                          <Fragment>
                          <Table
                                className="table-responsive bordered"
                            >
                            <thead className="bg-success text-white text-center" >
                                <tr>
                                    <th>{i18n.t("No.")}</th>
                                    <th>{i18n.t("Transaction No.")}</th>
                                    <th>{i18n.t("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.state.transactionList!=null ? 
                              this.state.transactionList.map((data,index)=>
                                <tr key={'data'+index} >
                                    <td className="text-gray-800 fw-bold text-hover-primary fs-4" style={{textAlign:'center'}}>
                                        {index+1}
                                    </td>
                                    <td style={{textAlign:'center'}}>
                                    <span 
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={()=>{
                                        // data.getData('getOrder')
                                        this.setState({showData:false},()=>this.updateShowDetail(true,data.id))
                                        
                                    }} className="text-gray-800 fw-bold text-hover-primary fs-4"> {data.no_po} </span>
                                    </td>
                                    <td style={{textAlign:'center'}}>
                                      <LabelTranscation value={data.status}></LabelTranscation>
                                    </td>
                                </tr>
                                )
                                
                            :null}
                            </tbody>
                          </Table>
                          </Fragment>
                          ):(<div className="table-loading-message">
                                <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                                <div className="text-center">{i18n.t("Loading")}</div>
                                </div>
                              )}
                          </div>
                          </React.Fragment>
                        </div>
											</div>
										</div>
										
									</div>
									
								</div>
              :null}
            
            

              	{this.state.showDataDetail ?
								<div className="w-100 flex-lg-row-fluid mx-lg-13">
									
									<div className="d-flex d-lg-none align-items-center justify-content-end mb-10">
										<div className="d-flex align-items-center gap-2">
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_start_sidebar_toggle">
												<i className="ki-duotone ki-profile-circle fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
											<div className="btn btn-icon btn-active-color-primary w-30px h-30px" id="kt_social_end_sidebar_toggle">
												<i className="ki-duotone ki-scroll fs-1">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
												</i>
											</div>
										</div>
									</div>
									
									
									<div className="card">
										
										<div className="card-header card-header-stretch " style={{justifyContent:'left'}}>
											
											<div className="card-title d-flex align-items-center">
												<i className="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0">
													<span className="path1"></span>
													<span className="path2"></span>
													<span className="path3"></span>
													<span className="path4"></span>
													<span className="path5"></span>
													<span className="path6"></span>
												</i>
												{/* <h3 className="fw-bold m-0 text-gray-800">{(timeline?.date)?moment(timeline?.date).format('MMMM DD, YYYY'):''}</h3> */}
											</div>
											<div className="card-toolbar m-0">
												<ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
													<li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 " data-bs-toggle="tab" role="tab" href="#purchaseRequest">{i18n.t("Purchase Request")}</a>
													</li>
													<li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#purchaseOrder">{i18n.t("Purchase Order")}</a>
													</li>
                          <li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 " data-bs-toggle="tab" role="tab" href="#receiving">{i18n.t("Receiving")}</a>
													</li>
                          <li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 " data-bs-toggle="tab" role="tab" href="#purchasing">{i18n.t("Purchasing")}</a>
													</li>
                          <li className="nav-item" role="presentation">
														<a className="nav-link justify-content-center text-active-gray-800 " data-bs-toggle="tab" role="tab" href="#payment">{i18n.t("Payment")}</a>
													</li>
												</ul>
											</div>
										</div>
									
                     <React.Fragment>
                          <div className={this.state.loadingDataDetail ? " table-loading" : ""}>
                          {!this.state.loadingDataDetail?
                          <Fragment>
										<div className="card-body">
                      <div className="tab-content">
                      <div id="purchaseRequest" className="card-body p-0 tab-pane fade show "  role="tabpanel" aria-labelledby="purchaseRequest">
                            <DataRequest
                              {...this.props}
                              {...this.state.orderList}
                             >
                          </DataRequest>
                        </div>
                        <div id="purchaseOrder" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="purchaseOrder">
                            <DataOrder
                              {...this.props}
                              {...this.state.orderList}
                             >
                            </DataOrder>
                        </div>
                        <div id="receiving" className="card-body p-0 tab-pane fade show " role="tabpanel" aria-labelledby="receiving">
                            <DataReceiving
                              {...this.props}
                              {...this.state.orderList}
                             >
                            </DataReceiving>
                        </div>
                        <div id="purchasing" className="card-body p-0 tab-pane fade show " role="tabpanel" aria-labelledby="purchasing">
                            <DataPurchasing
                              {...this.props}
                              {...this.state.orderList}
                            />
                        </div>
                        <div id="payment" className="card-body p-0 tab-pane fade show " role="tabpanel" aria-labelledby="payment">
                            <DataPayment
                              {...this.props}
                              {...this.state.orderList}
                            />
                        </div>
                       
											</div>
										</div>
                    </Fragment>:(<div className="table-loading-message">
                                <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                                <div className="text-center">{i18n.t("Loading")}</div>
                                </div>
                              )}
                              </div>
                            </React.Fragment> 
									</div>
									
								</div>
              :null}
							</div>
							
						</div>
						
					</div>}
        
      </div>
    );
  }
}

export default RecapAttendance;
