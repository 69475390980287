import React,{ useState } from "react"
import { Table } from "react-bootstrap"
import i18n from "../../../../i18n";
import LabelTranscation from "../../../utilities/Status/transaction";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";

export default function DataRequest(data){
    let arr = data?.request[0] || [];
    console.log('Request',arr)
    return (
        <div className="card mb-5 mb-xl-8">							
            <div className="card-body pt-5 px-0">
             {arr.length > 0? 
                <React.Fragment>
                <div className="d-flex flex-column text-left mb-9 px-9">
                   <table>
                    <tbody className="text-gray-800 fw-bold fs-5">
                        <tr>
                            <td>
                                {i18n.t('Date')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.date}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('No. PR')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.pr_no}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('Status')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <LabelTranscation value={arr.status}></LabelTranscation>
                            </td>
                        </tr>
                    </tbody>
                   </table>
                </div>
                <div className="d-flex flex-column text-left mb-9 px-9">
                  
                    <div className="text-left">
                    <Table
                    className="table-responsive table-index"
                >
                    <thead className="bg-success text-white text-center" >
                        <tr>
                            <th>{i18n.t("No.")}</th>
                            <th>{i18n.t("Name Item")}</th>
                            <th>{i18n.t("Unit")}</th>
                            <th>{i18n.t("Qty")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {arr.req_detail?.map((item,index)=>
                        <tr key={'detail'+index} >
                            <td className="text-gray-800 fw-bold fs-5" style={{textAlign:'center'}}>
                                {index+1}
                            </td>
                            <td style={{textAlign:'center'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {item.name_item} </span>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {item.name_unit} </span>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(item.qty,0)} </span>
                            </td>
                          
                        </tr>
                        )}
                    </tbody>
               </Table>
                        
                        
                        
            </div>
                    
                </div>
                </React.Fragment>
                :<div className="text-center"><h2>{i18n.t("Not Have Data About Purchase Request")}</h2></div>}
            </div>
            
        </div>
    )
}